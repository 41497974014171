import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';

import BottomLineTitle from '../../../components/Shared/Typography/BottomLineTitle';
import MuiTypography from '../../../components/Shared/Typography/MuiTypography';
import ReportType from '../../../components/ReportType/ReportType';
import DateFilter from '../../../components/DateFilter/DateFilter';
import FilterBoxContainer from '../../../components/FilterBoxContainer/FilterBoxContainer';
import { useStyles } from './EkgReportStyles';
import { black } from '../../../style/colors';
import { fontFamily } from '../../../style/commonStyles';
import {
  CRP_REGION,
  CRP_SERVICE,
  NO_REPORT_MESSAGE,
  REPORT_ERROR_MESSAGE,
  REPORT_TYPE_MENU_OPTIONS,
} from 'constants/ekgReportMenuOptionsConstants';
import { yearMonthDateFormat } from 'utilities/Utilities';
import {
  isClearClickedStatus,
  exportReport,
  noReportForSelectedFilters,
  getMembersData,
} from 'store/actions';
import { VALID_AUTH_REGIONS } from 'screens/common/Login/authConstants';
import { CLEAR_ALL_MESSAGE, EXPORT_TO_CSV, REPORTS } from 'constants/app';

let selectedService = '';
let physiciansList = [];

let CRP_PRACTICE = [];

const onSelectService = (service) => {
  selectedService = service;
  selectedPhysiciansHandler([]);
};

let selectedDateFilter = [];
const onDateSelection = (selectedDate) => {
  selectedDateFilter = [...selectedDate];
};

let selectedPracticesList = [];
const selectedPracticesHandler = (selectedItems) => {
  selectedPracticesList = [...selectedItems];
};

let selectedStatesList = [];
const selectedStatesHandler = (selectedItems) => {
  selectedStatesList = [...selectedItems];
};

let selectedPhysiciansList = [];

const selectedPhysiciansHandler = (selectedItems) => {
  selectedPhysiciansList = [...selectedItems];
};

function EkgReport() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedReportType, setSelectedReportType] = useState('');
  const [practiceListLength, setPracticeListLength] = useState(0);
  const [isExportClicked, setIsExportClicked] = useState(false);

  const membersData = useSelector((state) => state.ekgReport.membersData);
  const isNoReportForSelectedFilters = useSelector(
    (state) => state.ekgReport.isNoReportForSelectedFilters,
  );
  const isErrorGeneratingReport = useSelector((state) => state.ekgReport.isErrorGeneratingReport);
  const team = useSelector((state) => state.user.team);

  const isUsRegion = localStorage.getItem(CRP_REGION) === VALID_AUTH_REGIONS?.US;

  let CRP_STATES = [];
  let CRP_PHYSICIANS = [];
  let i = 0;
  let j = 0;

  // Pushing all the practicenors in a state
  useEffect(() => {
    if (team) {
      CRP_PRACTICE.push({
        id: 0,
        practiceName: team.name,
      });
      dispatch(getMembersData(team.id));
    }
    return () => {
      CRP_PRACTICE = [];
    };
  }, []);

  // Onchange ReportType functionality
  const onSelectReportType = (reportType) => {
    setSelectedReportType(reportType);
  };

  // Onchange practice functionality
  const onSelectPracticeItem = (isEmpty) => {
    setPracticeListLength(isEmpty);
  };

  if (membersData.length) {
    physiciansList = membersData.filter((data) => data.member.permissions.isPhysician === true);
    let allStates = [];
    let states = new Set();

    for (let i = 0; i < physiciansList.length; i++) {
      //to get all regions (even duplicated ones)
      // if (isUsRegion) {
      allStates = [...allStates, ...(physiciansList[i].member.regions || [])];
      // } else {
      //   //only including if country is not null
      //   if (physiciansList[i].member.country)
      //     allStates = [...allStates, physiciansList[i].member.country];
      // }
      //to get names of all physicians
      CRP_PHYSICIANS.push({
        id: j,
        physicianName: `${physiciansList[i].member.firstName} ${physiciansList[i].member.lastName}`,
        physicianId: `${physiciansList[i].member.id}`,
      });
      j++;
    }
    allStates.sort();
    //to get unique regions out of allStates array
    allStates.forEach((item) => states.add(item));

    //adding id key to each array item
    for (let item of states) {
      CRP_STATES.push({ id: i, stateName: item });
      i++;
    }
  }

  // Clear all the filers of Report
  const clearAllHandler = () => {
    dispatch(isClearClickedStatus(true));
    dispatch(noReportForSelectedFilters(false));
    setIsExportClicked(false);
    setPracticeListLength(0);
    selectedPhysiciansList = [];
  };

  // API call to get the csv file of Report based on the filters applied
  const exportHandler = () => {
    setIsExportClicked(true);
    if (
      selectedService === '' ||
      selectedReportType === '' ||
      !selectedDateFilter[0] ||
      !selectedDateFilter[1] ||
      !selectedPracticesList.length
    ) {
      return;
    }
    const selectedFilters = {
      service: selectedService,
      reportType: selectedReportType,
      startDate: yearMonthDateFormat(selectedDateFilter[0]),
      endDate: yearMonthDateFormat(selectedDateFilter[1]),
      practice: selectedPracticesList,
      state: selectedStatesList,
      physician: selectedPhysiciansList,
    };
    dispatch(exportReport(selectedFilters));
    clearAllHandler();
  };
  return (
    <Box className={classes.container}>
      <Grid container>
        <Grid item className={classes.topMargin}>
          <MuiTypography
            fontSize="24px"
            fontFamily={fontFamily}
            lineHeight="28px"
            letterSpacing="0.25px"
            fontWeight={500}
            color={black}
          >
            {REPORTS}
          </MuiTypography>
          <BottomLineTitle />
        </Grid>
      </Grid>
      <Box></Box>
      <Paper className={classes.paperRoot}>
        <Box>
          <ReportType
            heading="Report Type:"
            FilterOneName="Service*"
            FilterTwoName="Report Type*"
            CRP_SERVICE={CRP_SERVICE}
            REPORT_TYPE_MENU_OPTIONS={REPORT_TYPE_MENU_OPTIONS}
            onSelectService={onSelectService}
            onSelectReportType={onSelectReportType}
            isExportClicked={isExportClicked}
          />
          <DateFilter
            onDateSelection={onDateSelection}
            selectedReportType={selectedReportType}
            isExportClicked={isExportClicked}
          />
          <FilterBoxContainer
            heading="Filter By:"
            FilterOneName="Practice*"
            FilterTwoName={'State'}
            FilterThreeName="Physician"
            CRP_PRACTICE={CRP_PRACTICE}
            practiceListLength={practiceListLength}
            onSelectPracticeItem={onSelectPracticeItem}
            CRP_STATES={CRP_STATES}
            CRP_PHYSICIANS={CRP_PHYSICIANS}
            selectedPracticesHandler={selectedPracticesHandler}
            selectedStatesHandler={selectedStatesHandler}
            selectedPhysiciansHandler={selectedPhysiciansHandler}
            selectedReportType={selectedReportType}
            isExportClicked={isExportClicked}
            isUsRegion={isUsRegion}
          />
        </Box>
        {isNoReportForSelectedFilters && (
          <Box>
            <Typography className={classes.errorMessage}>{NO_REPORT_MESSAGE}</Typography>
          </Box>
        )}
        {isErrorGeneratingReport && (
          <Box>
            <Typography className={classes.errorMessage}>{REPORT_ERROR_MESSAGE}</Typography>
          </Box>
        )}
        <Box className={classes.buttonContainer}>
          <Button className={classes.clearButton} onClick={clearAllHandler}>
            {CLEAR_ALL_MESSAGE}
          </Button>
          <Button className={classes.exportButton} onClick={exportHandler}>
            {EXPORT_TO_CSV}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default EkgReport;
