import normal from 'assets/images/vectors/normal.png';
import abnormal from 'assets/images/vectors/abnornal.png';
import critical from 'assets/images/vectors/critical.png';
import noAnalysis from 'assets/images/vectors/noAnalysis.png';

export const EKG_EMOJI = {
  NORMAL: normal,
  NORMAL_SINUS_RHYTHM: normal,
  OTHER: abnormal,
  TACHYCARDIA: abnormal,
  BRADYCARDIA: abnormal,
  HEART_RATE_OVER_120: abnormal,
  HEART_RATE_UNDER_50: abnormal,
  INCONCLUSIVE: abnormal,
  AFIB: critical,
  SINUS_RHYTHM: critical,
  WIDE_QRS: critical,
  SINUSRHYTHM: critical,
  'SINUS_RHYTHM,WIDE_QRS': critical,
  NO_ANALYSIS: noAnalysis,
  TOO_SHORT: noAnalysis,
  TOO_LONG: noAnalysis,
  UNREADABLE: noAnalysis,
  INCONCLUSIVE_POOR_READING: noAnalysis,
};
