import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

import { GoAPI, TeleKardiaAPI } from 'Axios/axios';
import { getToken } from 'auth/AuthUtilities';
import TeamAlert from 'components/Shared/Alert/TeamAlert';
import { SectionMain } from 'components/mui/sections/SectionMain';
import { dayCode, dayLabel, headerText, initialdata } from '../constant/Constant';
import { handletoggleHeader } from 'store/actions';
import InvitedProviders from '../Teammates/InvitedProviders';
import { RegionList } from 'components/Forms/constants';

const TeamSettings = () => {
  const dispatch = useDispatch();
  const [settings, setsettings] = useState([]);
  const [coveredState, setcoveredState] = useState([]);
  const [showAlert, setShowAlert] = useState({
    data: '',
    error: false,
  });

  const teamId = useSelector((state) => state.user.team.id);
  const profileId = useSelector((state) => state.user.profile.id);
  const practiceName = useSelector((state) => state.user.team.name);

  useEffect(() => {
    dispatch(handletoggleHeader(headerText.TAB3, true));
    getBusinessHours();
    getCoveredState();
  }, []);

  const getCoveredState = () => {
    GoAPI.get(`/i/v1/members/${profileId}/states`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => setcoveredState(res.data))
      .catch((err) => console.log(err));
  };

  const getBusinessHours = () => {
    TeleKardiaAPI.get(`api/v1/teams/${teamId}/admin/${profileId}/businessHour`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        let incomingData = res.data.result.reduce((acc, cur) => {
          return [
            ...acc,
            {
              id: cur.id,
              label: dayLabel[cur.dayOfWeek],
              checked: true,
              startTime: cur.businessHourStart,
              endTime: cur.businessHourEnd,
            },
          ];
        }, []);

        setsettings(
          initialdata.map((initialWeek) => {
            let matchingArra1Obj = incomingData.find(
              (apidata) => apidata.label === initialWeek.label && apidata.checked,
            );
            if (matchingArra1Obj) {
              return { ...matchingArra1Obj };
            } else {
              return { ...initialWeek };
            }
          }),
        );
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (id, { type, name, value, checked = false }) => {
    if (name === 'standardHrs') {
      checked
        ? setsettings(
            settings.reduce((acc, cur) => {
              return [
                ...acc,
                {
                  startTime: '09:00',
                  endTime: '18:00',
                  checked: cur.checked,
                  label: cur.label,
                  id: cur.id,
                },
              ];
            }, []),
          )
        : getBusinessHours();
    } else {
      setsettings((prev) =>
        prev.map((setting) =>
          setting.id === id
            ? {
                ...setting,
                [name]: type === 'time' ? value : !setting.checked,
              }
            : setting,
        ),
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let outgoingData = settings
      .filter((item) => item.checked)
      .reduce(
        (acc, cur) => [
          ...acc,
          {
            businessHourEnd: cur.endTime,
            businessHourStart: cur.startTime,
            dayOfWeek: dayCode[cur.label],
          },
        ],
        [],
      );

    let single = false;
    let baseUrl = single
      ? `api/v1/teams/${teamId}/admin/${profileId}/businessHour`
      : `api/v1/teams/${teamId}/admin/${profileId}/businessHour/multiple`;
    let baseApi = single ? TeleKardiaAPI.post : TeleKardiaAPI.put;

    baseApi(baseUrl, outgoingData, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        getBusinessHours();
        setShowAlert({
          data: 'Joining invite has been sent, please check your email to know more',
          error: false,
        });
        setTimeout(() => {
          setShowAlert({ data: '', type: null });
        }, 5000);
      })
      .catch((err) => {
        setShowAlert({
          data: 'Something went wrong',
          error: true,
        });
        setTimeout(() => {
          setShowAlert({ data: '', type: null });
        }, 5000);
      });
  };

  return (
    <>
      <SectionMain
        headerElement={{
          headerText: 'Covered States',
        }}
        bodyElement={[
          {
            headerText: `${practiceName} covers the following states:`,
            childElements: (
              <Grid container>
                {coveredState &&
                  coveredState.map((item, i) => (
                    <Grid item xs={3} key={i}>
                      <Typography variant="body2">
                        {item !== '' && RegionList[item].name}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            ),
          },
        ]}
      />

      <InvitedProviders />

      {/* <form onSubmit={handleSubmit}>
        {showAlert.data !== '' && <TeamAlert title={showAlert.data} error={showAlert.error} />}

        <SectionMain
          headerElement={{
            headerText: 'Business Hours',
            childElements: (
              <>
                <Typography
                  component={'span'}
                  sx={{
                    color: 'rgb(0,0,0,0.87)',
                    fontSize: '16px',
                    fontFamily: 'Work Sans',
                    fontWeight: 600,
                  }}
                  variant="body1"
                >
                  Standard hours
                </Typography>
                <Switch name="standardHrs" onChange={(e) => handleChange(null, e.target)} />

                <Typography
                  component={'p'}
                  sx={{
                    color: 'rgb(0,0,0,0.87)',
                    fontFamily: 'Work Sans',
                    fontWeight: 400,
                  }}
                  variant="subtitle1"
                >
                  Monday through Friday, 8am - 5pm PST
                </Typography>
                <Typography
                  component={'p'}
                  sx={{
                    color: 'rgb(0,0,0,0.60)',
                    fontFamily: 'Work Sans',
                    fontWeight: 400,
                  }}
                  variant="body2"
                >
                  Turn on to use standard business hours
                </Typography>
              </>
            ),
          }}
          bodyElement={[
            {
              childElements: (
                <FormControl sx={{ width: '100%' }}>
                  <FormGroup sx={{ flexDirection: 'row', rowGap: 2, columnGap: 4 }}>
                    {settings.map((item) => (
                      <Grid key={item.id} container spacing={2}>
                        <Grid item lg={3} md={3} sm={12} sx={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.checked}
                                name="checked"
                                onChange={(e) => handleChange(item.id, e.target)}
                              />
                            }
                            label={item.label}
                          />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} sx={12}>
                          <TextField
                            fullWidth
                            label="Start Time"
                            type="time"
                            name="startTime"
                            value={item.startTime}
                            onChange={(e) => handleChange(item.id, e.target)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                          />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} sx={12}>
                          <TextField
                            fullWidth
                            // sx={{ width: '35%' }}
                            label="End Time"
                            type="time"
                            name="endTime"
                            value={item.endTime}
                            onChange={(e) => handleChange(item.id, e.target)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </FormGroup>
                </FormControl>
              ),
            },
          ]}
          footerElement={{
            buttonInputProps: (
              <>
                <Button variant="contained" type="submit">
                  Save Change
                </Button>
              </>
            ),
          }}
        />
      </form> */}
    </>
  );
};

export default TeamSettings;
