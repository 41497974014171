/* eslint-disable */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as actions from 'store/actions';
import { getToken, setResetIdleTime } from 'auth/AuthUtilities';
import { connect, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import {
  ChatKardiaAPI,
  CrpAPI,
  EscalationAPI,
  GoAPI,
  IntakeKardiaAPI,
  KccGoAPI,
  KproService,
  TeleKardiaAPI,
} from '../Axios/axios';
import SidebarLayout from 'components/SidebarLayout/index';
import * as Screens from 'screens/common';
import ContentHeader from 'components/ContentHeader';
import * as Constants from '../constants/app';
import { Paper, Container } from '@material-ui/core';

import AppNotification from 'components/AppNotification';
import EcgLoader from 'components/Shared/EcgLoader';
import VideoTeleConnect from 'screens/common/FloatingVideo/VideoTeleConnect';
import AutoCompleteDropdown from 'components/AutoCompleteDropdown';

import {
  AUTH_TYPE,
  COACH_ROUTES,
  DOCTOR_ROUTES,
  OPS_ROUTES,
  getPageHeaderTitle,
  ROUTES,
} from 'constants/app';
import * as DoctorRoutes from './routes/doctor';
import * as OpsRoutes from './routes/operations';
import * as CoachRoutes from './routes/coach';
import AdhocCallWindow from 'screens/common/AdhocPhone/AdhocCallWindow';
import { getUserType } from 'store/utility';
import { countAPI } from 'store/actions/audio';
import { CRP_REGION } from 'constants/ekgReportMenuOptionsConstants';

const useStyles = makeStyles(() => ({
  accessDeniedContent: {
    backgroundColor: '#FFFFFF',
    margin: '50px 0 0 0 ',
    padding: '40px',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useNetwork = (onChange) => {
  const [status, setStatus] = useState(navigator.onLine);
  const handleChange = () => {
    if (typeof onChange === 'function') {
      onChange(navigator.onLine);
    }
    setStatus(navigator.onLine);
  };
  useEffect(() => {
    window.addEventListener('online', handleChange);
    window.addEventListener('offline', handleChange);
    return () => {
      window.removeEventListener('online', handleChange);
      window.removeEventListener('offline', handleChange);
    };
  }, []);
  return status;
};

const App = (props) => {
  const dispatch = useDispatch();

  //set user region as US as default
  localStorage.setItem(CRP_REGION, Constants.USER_REGION);

  const [permissions, setPermissions] = React.useState();
  const [access, setAccess] = React.useState(null);
  const [userType, setuserType] = React.useState(props.userType);
  const classes = useStyles();
  const [offlinealertopen, setofflinealertopen] = React.useState(false);
  // const [currentSocketUrl, setCurrentSocketUrl] = useState(null);
  // const SOCKET_URL_ONE = 'wss://echo.websocket.org';
  const [snackbaropen, setsnackbaropen] = React.useState(false);
  const [networkstatus, setnetworkstatus] = useState('online');
  const [routes, setRoutes] = useState([]);
  const isCallStarted = useSelector((state) => state.adhocCall.isFloatingWindow);
  const isTeleconnectStarted = useSelector((state) => state.user.isComponentMounted);
  // const handleNetworkChange = (online) => {
  //   online ? setofflinealertopen(false) : setofflinealertopen(true);
  //   online ? setnetworkstatus('online') : setnetworkstatus('We just offline');
  // };

  // const closeofflinepopup = () => {
  //   setofflinealertopen(false);
  // };
  // const onLine = useNetwork(handleNetworkChange);

  const handlesnackbarClose = (event, reason) => {
    setsnackbaropen(false);
  };

  const pageTitle = useMemo(
    () => getPageHeaderTitle(window.location.pathname, permissions?.isAdmin, props.userType),
    [window.location.pathname, permissions],
  );

  /**
   * setting axios default global headers
   */
  KccGoAPI.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
  KccGoAPI.defaults.headers.common['Content-Type'] = 'application/json';
  GoAPI.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
  TeleKardiaAPI.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
  ChatKardiaAPI.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
  EscalationAPI.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
  IntakeKardiaAPI.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
  KproService.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
  CrpAPI.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
  // =========== end gloabl header ==============//

  useEffect(() => {
    if (props.me) {
      const { permissions, access } = props.me || {};
      if (userType === undefined) {
        KccGoAPI.get('/i/v1/kcc/connection_info')
          .then((res) => {
            let memberData = getUserType(res.data.kccChatUserID)[0];
            setuserType(memberData.charAt(0).toUpperCase() + memberData.slice(1).toLowerCase());
          })
          .catch((error) => {
            console.log(error);
          });
      }
      let routeConfig = {
        userAndAdmin: null,
        user: null,
        admin: null,
      };
      if (userType === AUTH_TYPE.COACH) {
        routeConfig.userAndAdmin = CoachRoutes.CoachAdmin;
        routeConfig.user = CoachRoutes.Coach;
        routeConfig.admin = CoachRoutes.Admin;
      }
      if (userType === AUTH_TYPE.DOCTOR) {
        routeConfig.userAndAdmin = DoctorRoutes.DoctorAdmin;
        routeConfig.user = DoctorRoutes.Doctor;
        routeConfig.admin = DoctorRoutes.Admin;
      }
      if (userType === AUTH_TYPE.OPERATIONS) {
        routeConfig.admin = OpsRoutes.Admin;
      }
      setPermissions(permissions);
      setAccess(access);
      const { userAndAdmin, user, admin } = routeConfig;
      let r =
        permissions?.isAdmin && permissions?.isPhysician
          ? userAndAdmin
          : permissions?.isAdmin
          ? admin
          : user;
      setRoutes(r);
    }
    //audio and ekg count api
    dispatch(countAPI());
    dispatch(actions.getOverdueAndNotStartedCount());

    return () => setRoutes([]);
  }, [props.me, userType]);

  const redirectRootManager = (userType, isAdmin, access) => {
    let redirectRoute = null;
    switch (userType) {
      case AUTH_TYPE.COACH:
        redirectRoute = isAdmin
          ? COACH_ROUTES.ADMIN_TELEKARDIA_DASHBOARD
          : COACH_ROUTES.TELEKARDIA_DASHBOARD;
        break;
      case AUTH_TYPE.DOCTOR:
        redirectRoute = isAdmin
          ? DOCTOR_ROUTES.TELEKARDIA_ADMIN_DASHBOARD
          : access.isTeleConnect
          ? DOCTOR_ROUTES.TELEKARDIA_DASHBOARD
          : access.isAudioReview
          ? DOCTOR_ROUTES.TELEKARDIA_AUDIO
          : access.isECGReview
          ? DOCTOR_ROUTES.TELEKARDIA_ECG
          : ROUTES.SETTINGS;
        break;
      case AUTH_TYPE.OPERATIONS:
        redirectRoute = OPS_ROUTES.OPS_SHIPPING;
        break;
    }
    return redirectRoute;
  };
  return (
    <>
      {!routes || routes.length == 0 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <EcgLoader />
        </div>
      ) : (
        <SidebarLayout>
          <div className="container-fluid p-0">
            {isCallStarted && <AdhocCallWindow />}
            {isTeleconnectStarted && <VideoTeleConnect />}
            <AppNotification />

            <div onClick={setResetIdleTime}>
              {pageTitle && (
                <Container maxWidth="xl">
                  <ContentHeader
                    showLastRefresh={false}
                    showAddPatient={false}
                    text={pageTitle.name}
                    memberWaiting={false}
                    {...props}
                  />
                  {pageTitle.isSearchEnabled && (
                    <Paper style={{ padding: '10px' }}>
                      <AutoCompleteDropdown />
                    </Paper>
                  )}
                </Container>
              )}
              <div>
                <Switch>
                  {routes?.length > 0 && props.me && (
                    <Route exact path={ROUTES.ROOT}>
                      <Redirect
                        to={redirectRootManager(props.userType, permissions.isAdmin, access)}
                      />
                    </Route>
                  )}
                  {routes?.length > 0 &&
                    props.me &&
                    routes.map((route, index) => <Route key={index} {...route} />)}
                  {routes?.length > 0 && <Route component={Screens.NotFoundPage} />}
                </Switch>
              </div>
            </div>
            {/* <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbaropen}
          autoHideDuration={3000}
          onClose={handlesnackbarClose}
        >
          <Alert
            onClose={handlesnackbarClose}
            severity={networkstatus === 'online' ? 'success' : 'error'}
          >
            {networkstatus}
          </Alert>
        </Snackbar> */}
          </div>
        </SidebarLayout>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.user?.profile,
    userType: state?.user?.chatProfile?.userType,
    type: state?.user,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    onAddUser: (x) => dispatch(actions.addUser(x)),
    onLogOut: () => dispatch(actions.user_logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
