import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
// import { useSelector } from "react-redux";

import MultipleSelectBox from '../MultipleSelectBox/MultipleSelectBox';
import { useStyles } from './FilterBoxContainerStyles';
import { REPORT_TYPE_MENU_OPTIONS } from 'constants/ekgReportMenuOptionsConstants';

/*
It is a component which contains 3 multiple select boxes with their corresponding headings and 1 main heading
*/

function FilterBoxContainer(props) {
  const classes = useStyles();
  const {
    heading,
    FilterOneName,
    FilterTwoName,
    FilterThreeName,
    CRP_PRACTICE,
    CRP_STATES,
    CRP_PHYSICIANS,
    practiceListLength,
    onSelectPracticeItem,
    selectedPracticesHandler,
    selectedStatesHandler,
    selectedPhysiciansHandler,
    selectedReportType,
    isExportClicked,
    isUsRegion,
  } = props;
  return (
    <Box>
      <Box className={classes.headingContainer}>
        <Typography className={classes.heading}>{heading}</Typography>
      </Box>
      <Grid container>
        <Grid item xs={3}>
          <Box>
            <Typography
              className={`${classes.subHeading}  ${
                !practiceListLength && isExportClicked ? classes.errorSubHeading : ''
              }`}
            >
              {FilterOneName}
            </Typography>
          </Box>
          <MultipleSelectBox
            menuOptionsData={CRP_PRACTICE}
            getSelectedOptions={selectedPracticesHandler}
            onSelectItemEmptyCheck={onSelectPracticeItem}
            smallPillStyle={false}
            placeholder="Select Practice(s)"
            keyName="practiceName"
          />
        </Grid>
        <Grid item xs={3} className={`${classes.gridItem}`}>
          <Box>
            <Typography className={classes.subHeading}>{FilterTwoName}</Typography>
          </Box>
          <MultipleSelectBox
            menuOptionsData={CRP_STATES}
            getSelectedOptions={selectedStatesHandler}
            smallPillStyle={true}
            placeholder={'Select State(s)'}
            keyName="stateName"
            selectAllOption={true}
          />
        </Grid>
        <Grid item xs={3} className={`${classes.gridItem}`}>
          <Box>
            <Typography className={classes.subHeading}>{FilterThreeName}</Typography>
          </Box>
          <MultipleSelectBox
            menuOptionsData={CRP_PHYSICIANS}
            getSelectedOptions={selectedPhysiciansHandler}
            smallPillStyle={false}
            placeholder="Select Physician(s)"
            keyName="physicianName"
            keyTwoName="physicianId"
            selectAllOption={true}
            isClearSelectedItems={
              selectedReportType === REPORT_TYPE_MENU_OPTIONS[1].reportType ||
              selectedReportType === REPORT_TYPE_MENU_OPTIONS[2].reportType ||
              selectedReportType === REPORT_TYPE_MENU_OPTIONS[3].reportType
            }
            isDisabled={
              selectedReportType === REPORT_TYPE_MENU_OPTIONS[1].reportType ||
              selectedReportType === REPORT_TYPE_MENU_OPTIONS[2].reportType ||
              selectedReportType === REPORT_TYPE_MENU_OPTIONS[3].reportType
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default FilterBoxContainer;
