import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography, Grid, Tabs, Tab } from "@material-ui/core";

import MuiTypography from "../../../components/Shared/Typography/MuiTypography";
import BottomLineTitle from "../../../components/Shared/Typography/BottomLineTitle";
import TabPanel from "../../../components/TabPanel/TabPanel";
import Toast from "../../../components/Toast/Toast";
import { useStyles } from "./EkgListStyles";
import { black } from "../../../style/colors";
import { fontFamily } from "../../../style/commonStyles";
import { setSearchButtonClicked, setTabState,errorRetrievingSearchList,showToastHandler } from "store/actions";
import EkgListTable from "components/ekg/EkgListTable/EkgListTable";
import EkgSearch from "components/ekg/EkgSearch/EkgSearch";
import { COMPLETED, INCOMING } from "constants/audio";
import { reportSubmit } from "utilities/Utilities";
import { COMPLETTED, INCOMMING, NO_SEARCH_RESULT } from "constants/app";

const EkgList = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [enteredSearchString, setEnteredSearchString] = useState("");
  const [updatelist, setUpdatelist] = useState(1);
  const [isEmptyTableData, setIsEmptyTableData] = useState(false);
  const tabState = useSelector((state) => state.ekgList.tabState);
  const [value, setValue] = useState(tabState === INCOMMING ? 0 : 1);

  const isErrorInSearch = useSelector((state) => state.ekgSearch.searchError);
  const isLoadingData = useSelector(
    (state) => state.ekgListTable.isLoadingData
  );
  const isShowToastMessage = useSelector((state) => state.ekgReview.showToast);
  const reportId = useSelector((state) => state.ekgReview.reportId);
  const notstartedAndOverdueCount = useSelector(
    (state) => state.ekgListTable.notstartedAndOverdueCount
  );

  // After mounting done, it should run cleanup function for emptying search bar
  useEffect(() => {
    return () => {
      dispatch(setSearchButtonClicked(false));
      dispatch(errorRetrievingSearchList(false));
    };
  }, []);

  //Refresh functionality of table
  const onRefreshClick = () => {
    setUpdatelist((prev) => prev + 1);
    setEnteredSearchString("");
  };

  // setting incomming and completed tab in a state
  const handleChange = (event, newValue) => {
    if (isLoadingData) {
      return;
    }
    setValue(newValue);
  };

  // Search the table on the basis of Search button event
  const onClickHandler = (enteredSearchString, count) => {
    setEnteredSearchString(enteredSearchString);
  };


  // Emptying serach query and loading the table on changing the tab on clicking Incomming request
  const incomingClickHandler = () => {
    if (isLoadingData) {
      return;
    }
    dispatch(setTabState(INCOMMING));
    dispatch(setSearchButtonClicked(false));
    setEnteredSearchString("");
    setUpdatelist(1);
    setIsEmptyTableData(false);
  };

    // Emptying serach query and loading the table on changing the tab on clicking Completed request
  const completedClickHandler = () => {
    if (isLoadingData) {
      return;
    }
    dispatch(setTabState(COMPLETTED));
    setEnteredSearchString("");
    setUpdatelist(1);
    dispatch(setSearchButtonClicked(false));
    setIsEmptyTableData(false);
  };

  //Closing the toast 
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(showToastHandler(false));
  };

  // closing the search option bar
  const handleSearchErrorToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(errorRetrievingSearchList(false));
  };

  const incomingTotatCountBadge = (
    <Box className={classes.incomingAndTotalBadgeContainer}>
      <Typography
        component="span"
        className={`${classes.tabHeading} ${
          tabState === INCOMMING ? classes.tabSelected : classes.tabNotSelected
        }`}
      >
        {INCOMING.toUpperCase()}
      </Typography>
      {tabState === INCOMMING && notstartedAndOverdueCount !== 0 ? (
        <Box className={classes.totalCountBadgeContainer}>
          <Typography component="p" className={classes.totalCountBadge}>
            {notstartedAndOverdueCount}
          </Typography>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
  const completedTotatCountBadge = (
    <Box>
      <Typography
        component="span"
        className={`${classes.tabHeading} ${
          tabState === COMPLETTED ? classes.tabSelected : classes.tabNotSelected
        }`}
      >
        {COMPLETED.toUpperCase()}
      </Typography>
    </Box>
  );

  return (
    <Box className={classes.container}>
      <Grid>
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item style={{ marginTop: "2.5rem" }}>
            <MuiTypography
              fontSize="24px"
              fontFamily={fontFamily}
              lineHeight="28px"
              letterSpacing="0.25px"
              fontWeight={500}
              color={black}
            >
              {t("EKG WorkList")}
            </MuiTypography>
            <BottomLineTitle />
          </Grid>
        </Grid>
        <Box>

          {isShowToastMessage && (
            <Box className={classes.toastContainer}>
              <Toast
                open={isShowToastMessage}
                closeHandler={handleClose}
                alertMessage={reportSubmit(reportId)}
                severity="success"
              />
            </Box>
          )}
          {isErrorInSearch && (
            <Box className={classes.toastContainer}>
              <Toast
                open={isErrorInSearch}
                closeHandler={handleSearchErrorToastClose}
                alertMessage={NO_SEARCH_RESULT}
                severity="error"
              />
            </Box>
          )}
          <Box m={2} />
          <Box m={2} />
          <Tabs
            classes={{
              root: classes.customTabRoot,
              indicator: classes.customTabIndicator,
            }}
            value={value}
            onChange={handleChange}
            centered
            variant="fullWidth"
          >
            <Tab
              label={incomingTotatCountBadge}
              onClick={incomingClickHandler}
              disabled={isLoadingData && tabState !== INCOMMING}
            />
            <Tab
              label={completedTotatCountBadge}
              onClick={completedClickHandler}
              disabled={isLoadingData && tabState !== COMPLETTED}
            />
          </Tabs>
          <EkgSearch
            setIsEmptyTableData={setIsEmptyTableData}
            clickHandler={onClickHandler}
            onRefreshClick={onRefreshClick}
          />
          <TabPanel value={value} index={0}>
            <EkgListTable
              isEmptyTableData={isEmptyTableData}
              enteredSearchString={enteredSearchString}
              refresh={updatelist}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EkgListTable
              isEmptyTableData={isEmptyTableData}
              enteredSearchString={enteredSearchString}
              refresh={updatelist}
            />
          </TabPanel>
        </Box>
      </Grid>
    </Box>
  );
};

export default EkgList;
