import { ChatContext } from 'providers/chat';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getFullJid } from 'utilities/Utilities';
import ListItem from './ListItem';

const ChatList = ({ memberList, chatUserId }) => {
  const { messagePoolObject, unreadMsgCount, setUnreadMsgCount } = useContext(ChatContext);

  const history = useHistory();
  const [sortedList, setSortedList] = useState([...memberList]);
  const sortMemberListOnNewUpdate = () => {
    console.log('SORTING INBOX!');
    //fetch the existing member list
    //map through and add instances of all messages if found
    //sort and display.
    let sml = [...memberList];
    sml.map((item) => {
      item.time = null;
      item.text = null;
      if (messagePoolObject[item.chatUserId]) {
        item.time = messagePoolObject[item.chatUserId].time;
        item.text = messagePoolObject[item.chatUserId]?.text;
      }
    });
    sml.sort(function (a, b) {
      return new Date(b.time) - new Date(a.time);
    });

    setSortedList(sml);
  };
  useEffect(() => {
    sortMemberListOnNewUpdate();
  }, [messagePoolObject]);
  return (
    <>
      {sortedList.map((row, index) => (
        <ListItem
          onClick={() => {
            unreadMsgCount[row.chatUserId] && setUnreadMsgCount((d) => {
              d[row.chatUserId] = 0
              return d;
            });
            history.push(`/chat-inbox/${row.chatUserId}`);
          }}
          key={index}
          item={row}
          selected={row.chatUserId === chatUserId}
          messagePoolObject={messagePoolObject}
          unreadMsgCount={unreadMsgCount}
        />
      ))}
    </>
  );
};

export default ChatList;
