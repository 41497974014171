import React, { useState } from 'react';
import { NavLink as NL } from 'react-router-dom';
import styles from '../NavigationSidebar.module.css';
import { Strophe } from 'strophe.js';
import { useTranslation } from 'react-i18next';

const renderLinkContent = (props) => {
  const { Icon, text, secondaryBullet = false, color = '#1976D2' } = props;

  return (
    <div className={styles.leftGroup}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {Icon && (
          <div className={styles.navIcon}>
            <Icon style={{ fill: color }} />
          </div>
        )}
        {secondaryBullet && <div className={styles.bullet} />}
        <span className={styles.navText}>{text}</span>
      </div>
    </div>
  );
};

const NavLink = (props) => {
  const { t } = useTranslation();
  let [count, setCount] = useState(0);
  const {
    className,
    activeClassName,
    to,
    href,
    onClick,
    target = '',
    text,
    unreadCount,
    style,
    status,
    alertCount,
    ekgCount,
    audioCount,
    sideImage,
  } = props;
  const args = { ...props };
  args.text = t(text);
  if (href) {
    return (
      <a href={href} className={styles.navExtLink} target={target}>
        {renderLinkContent(props)}
      </a>
    );
  }

  const renderSideNavComp = (text) => {
    localStorage.setItem('navText', text);
    if (!sideImage) {
      localStorage.setItem('navChildText', '');
    }
  };

  return (
    <NL style={style} className={className} activeClassName={activeClassName} to={to}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        // onClick={() => renderSideNavComp(text)}
      >
        {renderLinkContent(props)}
        {text === 'Inbox' && (
          <div
            style={{
              margin: '0 10px',
              width: '5px',
              height: '5px',
              borderRadius: '50%',
              backgroundColor:
                status === Strophe.Status.CONNECTED
                  ? 'blue'
                  : status === Strophe.Status.AUTHFAIL
                  ? 'red'
                  : 'grey',
            }}
          ></div>
        )}
        {text === 'Inbox' && unreadCount > 0 && <div className={styles.count}>{unreadCount}</div>}
        {text === 'Alerts' && alertCount > 0 && <div className={styles.count}>{alertCount}</div>}
        {text === 'EKGs' && ekgCount > 0 && <div className={styles.count}>{ekgCount}</div>}
        {text === 'Audio' && audioCount > 0 && <div className={styles.count}>{audioCount}</div>}
      </div>
    </NL>
  );
};

export default NavLink;
