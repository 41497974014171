/* eslint-disable react/jsx-key */
import * as React from 'react';
import { MuiTableComponent } from '../common/CustomUI';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import {
  Box,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Paper,
  Grid,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { getFullfilmentML, updateShippingDetails } from 'store/actions/fullfilment';
import ShippingDetailModal from './ShippingDetailModal';
import ActivityTimelineModal from './ActivityTimelineModal';
import SponsorPopover from 'screens/coach/MemberList/components/SponsorPopover';

import { programMap } from 'utilities/Constants';
import { getEKGAlgorithmDeterminationDisplayText } from 'constants/recordingMaps';
import { formatDate } from 'utilities/Utilities';

const MainContainer = styled(Container)(({ theme }) => ({
  '& .MuiTypography-body2': {
    fontSize: '14px',
    fontFamily: 'Work Sans',
  },
}));

export default function FullfilmentMemberList() {
  const dispatch = useDispatch();

  const { loading: mLloading, data: mLData } = useSelector((state) => state.fullfilment);

  const [currentMember, setCurrentMember] = React.useState(null);
  const [note, setNote] = React.useState('');
  const [openNoteModal, setOpenNoteModal] = React.useState(false);

  //=== states for MUI data grid table pagiantion (client side pagination) ==//
  const [filteredRow, setFilteredRow] = React.useState([]);
  const [totalRowCount, settotalRowCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const perPageOptions = [10, 20, 50];
  // ==== ends ====//

  function getDateFilterOperators(showTime = false) {
    return [
      {
        value: 'is',
        getApplyFilterFn: (filterItem) => {
          return buildApplyDateFilterFn(
            filterItem,
            (value1, value2) => value1 === value2,
            showTime,
          );
        },
        InputComponent: GridFilterDateInput,
        InputComponentProps: { showTime },
      },
      {
        value: 'not',
        getApplyFilterFn: (filterItem) => {
          return buildApplyDateFilterFn(
            filterItem,
            (value1, value2) => value1 !== value2,
            showTime,
          );
        },
        InputComponent: GridFilterDateInput,
        InputComponentProps: { showTime },
      },
      {
        value: 'after',
        getApplyFilterFn: (filterItem) => {
          return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 > value2, showTime);
        },
        InputComponent: GridFilterDateInput,
        InputComponentProps: { showTime },
      },
      {
        value: 'onOrAfter',
        getApplyFilterFn: (filterItem) => {
          return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 >= value2, showTime);
        },
        InputComponent: GridFilterDateInput,
        InputComponentProps: { showTime },
      },
      {
        value: 'before',
        getApplyFilterFn: (filterItem) => {
          return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 < value2, showTime);
        },
        InputComponent: GridFilterDateInput,
        InputComponentProps: { showTime },
      },
      {
        value: 'onOrBefore',
        getApplyFilterFn: (filterItem) => {
          return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 <= value2, showTime);
        },
        InputComponent: GridFilterDateInput,
        InputComponentProps: { showTime },
      },
      {
        value: 'isEmpty',
        getApplyFilterFn: () => {
          return ({ value }) => {
            return value == null;
          };
        },
        requiresFilterValue: false,
      },
      {
        value: 'isNotEmpty',
        getApplyFilterFn: () => {
          return ({ value }) => {
            return value != null;
          };
        },
        requiresFilterValue: false,
      },
    ];
  }

  function buildApplyDateFilterFn(filterItem, compareFn, showTime = false) {
    if (!filterItem.value) {
      return null;
    }

    // Make a copy of the date to not reset the hours in the original object
    const filterValueCopy = new Date(filterItem.value);
    filterValueCopy.setHours(0, 0, 0, 0);

    const filterValueMs = filterValueCopy.getTime();

    return ({ value }) => {
      if (!value) {
        return false;
      }

      // Make a copy of the date to not reset the hours in the original object
      const dateCopy = new Date(value);
      dateCopy.setHours(showTime ? value.getHours() : 0, showTime ? value.getMinutes() : 0, 0, 0);
      const cellValueMs = dateCopy.getTime();

      return compareFn(cellValueMs, filterValueMs);
    };
  }

  function GridFilterDateInput(props) {
    const { item, showTime, applyValue, apiRef } = props;

    const Component = showTime ? DateTimePicker : DatePicker;

    const handleFilterChange = (newValue) => {
      applyValue({ ...item, value: newValue });
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Component
          value={item.value || null}
          label={apiRef.current.getLocaleText('filterPanelInputLabel')}
          slotProps={{
            textField: {
              variant: 'standard',
            },
            inputAdornment: {
              sx: {
                '& .MuiButtonBase-root': {
                  marginRight: -1,
                },
              },
            },
          }}
          onChange={handleFilterChange}
        />
      </LocalizationProvider>
    );
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: true,
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => <Typography variant="body2">{params?.row?.id}</Typography>,
    },
    {
      field: 'custom_participant_id',
      headerName: 'Participant ID',
      filterable: true,
      renderCell: (params) => (
        <Typography variant="body2">{params?.row?.custom_participant_id}</Typography>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Creation Date',
      width: 150,
      type: 'date',
      valueFormatter: ({ value }) => `${formatDate(value)}`,
      renderCell: (params) => (
        <Typography variant="body2">{formatDate(params?.row?.created_at)}</Typography>
      ),
    },
    {
      field: 'created_time',
      headerName: 'Creation Time',
      width: 150,
      type: 'date',
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => <Typography variant="body2">{params?.row?.created_time}</Typography>,
    },
    {
      field: 'lastLogin',
      headerName: 'Last Login',
      width: 150,
      type: 'date',
      valueFormatter: ({ value }) => `${formatDate(value)}`,
      renderCell: (params) => (
        <Typography variant="body2">
          {params?.row?.lastLogin == null ? '--' : formatDate(params?.row?.lastLogin)}
        </Typography>
      ),
    },
    {
      field: 'sponsor_symbol',
      headerName: 'Sponsor',
      width: 120,
      type: 'string',
      renderCell: (params) => (
        <Typography variant="body2">{params?.row?.sponsor_symbol}</Typography>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 220,
      editable: false,
      type: 'string',
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => <Typography variant="body2">{`${params?.row?.name}`}</Typography>,
    },
    {
      field: 'phone_number',
      headerName: 'Phone Number',
      width: 180,
      type: 'string',
      renderCell: (params) => <Typography variant="body2">{params?.row?.phone_number}</Typography>,
    },
    {
      field: 'encrypted_email',
      headerName: 'Email',
      width: 250,
      type: 'string',
      renderCell: (params) => (
        <Typography variant="body2">
          {params?.row?.encrypted_email ? (
            params?.row?.encrypted_email?.length > 25 ? (
              <SponsorPopover item={`${params?.row?.encrypted_email}`} length={25} />
            ) : (
              params?.row?.encrypted_email
            )
          ) : (
            '--'
          )}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      type: 'string',
      renderCell: (params) => <Typography variant="body2">{params?.row?.status}</Typography>,
    },

    //===== ASP-271 & ASP-283 ======
    // ===== add 3 extra field in fulfilment table ==== //
    //=== ASP-331 & ASP-342 == fix export and filter issue in lastLogin, lastEcg, lastBP, program columns //
    {
      field: 'recordingInfo',
      headerName: 'Last ECG',
      width: 260,
      type: 'string',
      filterOperators: getDateFilterOperators(),
      valueGetter: (params) =>
        params?.row?.recordingInfo
          ? `${formatDate(
              params?.row?.recordingInfo?.recordedAt,
            )} (${getEKGAlgorithmDeterminationDisplayText(
              params?.row?.recordingInfo?.algorithmDetermination,
            )})`
          : '--',
      renderCell: (params) => (
        <Typography variant="body2">
          {params?.row?.recordingInfo ? (
            getEKGAlgorithmDeterminationDisplayText(
              params?.row?.recordingInfo?.algorithmDetermination,
            ).length > 15 ? (
              <SponsorPopover
                item={`${formatDate(
                  params?.row?.recordingInfo?.recordedAt,
                )} (${getEKGAlgorithmDeterminationDisplayText(
                  params?.row?.recordingInfo?.algorithmDetermination,
                )})`}
                length={26}
              />
            ) : (
              `${formatDate(
                params?.row?.recordingInfo?.recordedAt,
              )} (${getEKGAlgorithmDeterminationDisplayText(
                params?.row?.recordingInfo?.algorithmDetermination,
              )})`
            )
          ) : (
            '--'
          )}
        </Typography>
      ),
    },
    {
      field: 'recordedAt',
      headerName: 'Last BP',
      width: 220,
      type: 'string',
      filterOperators: getDateFilterOperators(),
      valueGetter: (params) =>
        params?.row?.bpInfo
          ? `${formatDate(params?.row?.bpInfo?.recordedAt)} (${params?.row?.bpInfo?.systolic}/${
              params?.row?.bpInfo?.diastolic
            })`
          : '--',
      renderCell: (params) => (
        <Typography variant="body2">
          {params?.row?.bpInfo
            ? `${formatDate(params?.row?.bpInfo?.recordedAt)} (${params?.row?.bpInfo?.systolic}/${
                params?.row?.bpInfo?.diastolic
              })`
            : '--'}
        </Typography>
      ),
    },
    {
      field: 'programId',
      headerName: 'Program',
      width: 200,
      type: 'string',
      valueFormatter: ({ value }) => `${programMap[value]}`,
      renderCell: (params) => (
        <Typography variant="body2">
          {params?.row?.programId ? programMap[params?.row?.programId] : '--'}
        </Typography>
      ),
    },
    //==== end ASP-271 & ASP-283 & ASP-331, ASP-342====//

    {
      field: 'primary_line',
      headerName: 'Address Line 1',
      width: 220,
      type: 'string',
      renderCell: (params) => <Typography variant="body2">{params?.row?.primary_line}</Typography>,
    },
    {
      field: 'secondary_line',
      headerName: 'Address Line 2',
      width: 220,
      type: 'string',
      renderCell: (params) => (
        <Typography variant="body2">{params?.row?.secondary_line}</Typography>
      ),
    },
    {
      field: 'city',
      headerName: 'City',
      width: 220,
      type: 'string',
      renderCell: (params) => <Typography variant="body2">{params?.row?.city}</Typography>,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 120,
      type: 'string',
      renderCell: (params) => <Typography variant="body2">{params?.row?.state}</Typography>,
    },
    {
      field: 'postal_code',
      headerName: 'Zip Code',
      width: 120,
      type: 'string',
      renderCell: (params) => <Typography variant="body2">{params?.row?.postal_code}</Typography>,
    },
    {
      field: 'note',
      headerName: 'Note',
      width: 120,
      type: 'string',
      renderCell: (params) => <Typography variant="body2">{params?.row?.note}</Typography>,
    },
    {
      field: 'action',
      headerName: '',
      width: 50,
      sorting: false,
      hide: true,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          // icon={<SecurityIcon />}
          label="Add Note"
          onClick={openNoteHandler(params.row)}
          disabled={params.row.state == null}
          showInMenu
        />,
        <GridActionsCellItem
          label="Mark Shipped"
          onClick={markAsShippedHandler(params.row)}
          disabled={params.row.shipped_status == 'true' || params.row.state == null}
          showInMenu
        />,
        <GridActionsCellItem
          label="Member Detail"
          onClick={openMemberDetailHandler(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="View Timeline"
          disabled={!params.row.custom_participant_id}
          onClick={openMemberActivityTimelineHandler(params.row)}
          showInMenu
        />,
      ],
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    custom_participant_id: false,
    primary_line: false,
    secondary_line: false,
    city: false,
    state: false,
    postal_code: false,
    note: false,
  });
  // ========== x-data-grid column definition =========//
  React.useEffect(() => {
    dispatch(getFullfilmentML());
  }, []);

  React.useEffect(() => {
    if (!mLloading && mLData.length > 0) {
      mLData.map((m, index) => {
        // m.id = index + 1;
        m.created_time = moment(m.created_at).format('HH:MM A');
        m.name = `${m.last_name}, ${m.first_name}`;
      });
      setFilteredRow(mLData);
      settotalRowCount(mLData.length);
    }
  }, [mLloading, mLData]);

  //========== end =============//
  const submitNote = () => {
    let data = {
      status: currentMember.shipped_status || 'false',
      note: note,
    };
    updateShippingDetails(currentMember.custom_participant_id, data).then((res) => {
      handleNoteModalClose();
      dispatch(getFullfilmentML());
      setNote('');
    });
  };

  const markAsShippedHandler = React.useCallback(
    (params) => () => {
      let data = {
        status: 'true',
        note: params.note,
      };
      updateShippingDetails(params.custom_participant_id, data).then((res) => {
        dispatch(getFullfilmentML());
      });
    },
    [],
  );
  const openMemberDetailHandler = React.useCallback(
    (params) => () => {
      setCurrentMember(params);
      setOpenDismissModal(true);
      return;
    },
    [],
  );
  const openMemberActivityTimelineHandler = React.useCallback(
    (params) => () => {
      setCurrentMember(params);
      setOpenActivityModal(true);
      return;
    },
    [],
  );

  const openNoteHandler = React.useCallback(
    (params) => () => {
      setCurrentMember(params);
      setOpenNoteModal(true);
      return;
    },
    [],
  );
  const handleNoteModalClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenNoteModal(false);
  };

  //======== end ===========//

  const [openDismissModal, setOpenDismissModal] = React.useState(false);
  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenDismissModal(false);
  };

  const [openActivityModal, setOpenActivityModal] = React.useState(false);
  const handleActivityModalClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenActivityModal(false);
  };

  return (
    <MainContainer maxWidth="xl">
      <MuiTableComponent
        columns={columns}
        rows={filteredRow}
        initialState={{
          sorting: {
            sortModel: [{ field: 'created_at', sort: 'desc' }],
          },
        }}
        loading={mLloading}
        columnToolbar={true}
        filterToolbar={true}
        densityToolbar={true}
        exportToolbar={true}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        rowsPerPageOptions={perPageOptions}
        rowCount={totalRowCount}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        ColumnResizeIcon={null}
        setFilterModel={() => {}}
      />

      {openDismissModal && currentMember && (
        <ShippingDetailModal
          currentMember={currentMember}
          handleClose={handleClose}
          openDismissModal={openDismissModal}
        />
      )}
      {openActivityModal && currentMember && (
        <ActivityTimelineModal
          currentMember={currentMember}
          handleClose={handleActivityModalClose}
          openDismissModal={openActivityModal}
        />
      )}
      {openNoteModal && (
        <Dialog
          onClose={handleNoteModalClose}
          aria-labelledby="customized-dialog-title"
          open={openNoteModal}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <Typography
              variant="h5"
              style={{
                fontFamily: 'Work Sans',
              }}
            >
              {'Add Note'}
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Box>
              <Paper
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  marginTop: '16px',
                  borderRadius: '8px',
                  padding: '10px 20px',
                  backgroundColor: 'white !important',
                  width: '400px',
                }}
              >
                <Box style={{ marginBottom: '10px' }}>
                  <TextField
                    id="txt2"
                    label="Shipping note"
                    defaultValue={note}
                    onChange={(e) => setNote(e.target.value)}
                    placeholder={'Type your note here..'}
                    multiline
                    maxRows={10}
                    fullWidth
                  />
                </Box>
                <Grid container flexDirection={'row-reverse'}>
                  <Grid item xs={3}>
                    <Button
                      style={{
                        color: '#067F6F',
                        border: '2px solid #067F6F',
                        padding: '10px 20px',
                        fontWeight: '800',
                        fontFamily: 'Work Sans',
                        lineHeight: '18px',
                        marginRight: '10px',
                      }}
                      onClick={() => handleNoteModalClose()}
                    >{`Cancel`}</Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      style={{
                        backgroundColor: '#067F6F',
                        color: 'white',
                        border: '2px solid #067F6F',
                        padding: '10px 20px',
                        fontWeight: '800',
                        fontFamily: 'Work Sans',
                        lineHeight: '18px',
                      }}
                      onClick={() => submitNote()}
                    >
                      {`Save`}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </MainContainer>
  );
}
