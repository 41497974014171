import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { useStyles } from './AudioStyle';
import { Box, Grid, InputBase, InputAdornment } from '@mui/material';
import { fontFamily } from '../../../style/commonStyles';
import { useTranslation } from 'react-i18next';
import MuiTypography from 'components/Shared/Typography/MuiTypography';
import { black } from '../../../style/colors';
import { DOCTOR_ROUTES, ERROR_MESSAGE, RETURN_TO_WORKLIST, TECHNICAL_ERROR } from 'constants/app';
import moment from 'moment';
import TabSelector from 'components/Shared/Tabs/Tabs';
import { ClickAwayListener, Paper, IconButton, Typography, List, Tooltip } from '@mui/material';
import { MuiTableComponent } from 'screens/common/CustomUI';
import { useHistory, useParams } from 'react-router-dom';
import * as actions from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { colorStatusObj } from 'style/colors';
import {
  changePage,
  changePageSize,
  emptyPatientData,
  errorMessageRetrieved,
  errorRetrievingPatientList,
  getReviewData,
  isFetchingPatientResults,
  setSearchButtonClicked,
  showToastMessage,
} from 'store/actions/audio';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import Loading from 'components/Loading';
import Toast from 'components/Toast/Toast';
import { nextMultipleOf10 } from 'utilities/Utilities';
import { AUDIO_INBOX, COMPLETED, INCOMING } from 'constants/audio';
import AlertDialog from 'components/Shared/Dialog/Dialog';

export const AudioScreen = () => {
  const { tab } = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [filteredRow, setFilteredRow] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [patientSearch, setPatientSearch] = React.useState('');
  const [tabCount, setTabCount] = useState(1);
  const [tabPage, setPageTab] = useState(0);
  const perPageOptions = [10, 20, 50];
  const [display, setDisplay] = useState(false);
  const [data, Setdata] = useState([]);
  const [searchValue, setSearchvalue] = useState('');
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
  });
  const [sortModel, setSortModel] = React.useState([]);

  const audioData = useSelector((state) => state.audio);
  const reviewData = useSelector((state) => state?.audio?.recording);
  const dataResults = useSelector((state) => {
    return state?.audioSearch?.searchresults;
  });
  const isLoadingSearchResults = useSelector((state) => state?.audioSearch?.isLoadingSearchResults);
  const isErrorInSearch = useSelector((state) => state?.audioSearch?.searchError);
  const permissions = useSelector((state) => state.user.profile.permissions);

  // Audio table information of columns data
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 230,
      editable: false,
      type: 'string',
      renderCell: (params) => (
        <p style={{ color: '#142A39', fontWeight: 500 }}>
          {`${params?.row?.lastName}, ${params?.row?.firstName}`}
        </p>
      ),
    },
    {
      field: 'reportId',
      headerName: 'Report ID',
      width: 230,
      type: 'string',
      renderCell: (params) => (
        <p style={{ color: '#142A39', fontWeight: 500 }}>{`${params?.row?.reportId}`}</p>
      ),
    },
    {
      field: 'timeRequested',
      headerName: 'Time Requested',
      width: 230,
      editable: false,
      type: 'date',
      renderCell: (params) => (
        <p style={{ color: '#142A39', fontWeight: 500 }}>
          {moment(params?.row?.createdAt).format('MM-DD-YYYY hh:mm A')}
        </p>
      ),
    },
    tabPage === 0
      ? {
          field: 'timeLeft',
          headerName: 'Time Left',
          width: 230,
          editable: false,
          type: 'date',
          renderCell: (params) => (
            <p style={{ color: params?.row?.timeLeft?.includes('-') ? '#CC3D3F' : '#067F6F' }}>
              {params?.row?.timeLeft}
            </p>
          ),
        }
      : {
          field: 'submittedAt',
          headerName: 'Time Submitted',
          width: 230,
          editable: false,
          type: 'date',
          renderCell: (params) => (
            <p style={{ fontWeight: 500 }}>
              {moment(params?.row?.submittedAt).format('MM-DD-YYYY hh:mm A')}
            </p>
          ),
        },
    {
      field: 'status',
      headerName: 'Status',
      width: 230,
      editable: false,
      type: 'string',
      renderCell: (params) => (
        <p
          style={{
            fontWeight: 500,
            border: `2px solid ${colorStatusObj[params?.row?.status]}`,
            padding: '8px 14px',
            borderRadius: '17px',
          }}
        >
          {`${params?.row?.status}`}
        </p>
      ),
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 230,
      editable: false,
      type: 'string',
      sorting: false,
      renderCell: (params) => <p style={{ fontWeight: 500 }}>{params.row.region.toUpperCase()}</p>,
    },
  ];

  // Get the audio table information for both incoming and completed requests
  useEffect(() => {
    tabPage !== null &&
      dispatch(
        actions.getAudioData(
          tabPage === 0 ? INCOMING : COMPLETED,
          audioData?.pageSize,
          audioData?.page,
          searchValue,
        ),
      )
        .then((res) => {
          let resultData = res?.data?.data?.result;

          setFilteredRow(
            resultData.reduce((acc, cur) => {
              return [
                ...acc,
                {
                  ...cur,
                  name: `${cur.lastName}, ${cur.firstName}`,
                },
              ];
            }, []),
          );
          setTabCount(tabPage === 0 ? res?.data?.data?.count : res?.data?.data?.totalCount);
        })
        .catch((err) => console.log(err));
  }, [audioData?.page, audioData?.pageSize, tabPage]);

  // Get the search data information
  useEffect(() => {
    if (searchValue.length > 1) {
      dispatch(actions.getAudioSearchData(searchValue, tabPage === 0 ? INCOMING : COMPLETED));
      setDisplay(true);
    }
  }, [searchValue]);

  // Setting the search data information in a state if it exists
  useEffect(() => {
    if (dataResults) {
      Setdata(dataResults);
    }
  }, [dataResults]);

  // every time the component mounts and page rendered with the change of incomming and complete requests, we are emptying search data
  useLayoutEffect(() => {
    setDisplay(false);
    setSearchvalue('');
    Setdata([]);
  }, [tabPage]);

  useEffect(() => {
    tab == undefined ? setPageTab(0) : setPageTab(parseInt(tab));
  }, [tab]);

  // setting the value of incomming and complete requests in a state every time we change tabs
  const getTabValue = useCallback(
    (value) => {
      setPageTab(value);
    },
    [tabPage],
  );

  // Closes the modal
  const handleClickClose = () => {
    dispatch(errorMessageRetrieved(null));
  };

  // closing or emptying the search info
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(showToastMessage(false));
    dispatch(errorRetrievingPatientList(false));
  };

  // Filtering table with search
  const setFilterModel = (val) => {
    if (
      val.items.length === 0 ||
      (val.items.length > 0 && (val.items[0].value === '' || val.items[0].value === undefined))
    ) {
      setPatientSearch('');
    } else {
      val.items[0].columnField === 'name' && setPatientSearch(`&name=${val.items[0].value}`);
    }
  };

  // Set the search option applied or not
  const onSearchClick = () => {
    if (searchValue.trim().length === 0) {
      return;
    }
    if (isErrorInSearch) {
      setSearchvalue('');
      setDisplay(false);
      return;
    }
    dispatch(setSearchButtonClicked(true));
    Setdata([]);
    setDisplay(false);
    handleSearch();
  };

  const handleClickAway = () => {
    setDisplay(false);
  };

  // Checks if the tabe row is clickable or not
  const handleRowClick = (params, data) => {
    let rowData = data !== undefined ? data : params?.row;
    dispatch(getReviewData({ ...rowData, tabPage: tabPage }));
    dispatch(emptyPatientData());
    history.push(
      `${DOCTOR_ROUTES.TELEKARDIA_AUDIO_REVIEW}/${rowData?.id}/${
        tabPage === 0 ? INCOMING : COMPLETED
      }`,
    );
  };

  // Call the Search API to get the filtered data
  const handleSearch = () => {
    dispatch(
      actions.getAudioData(
        tabPage === 0 ? INCOMING : COMPLETED,
        audioData?.pageSize,
        audioData?.page,
        searchValue,
      ),
    )
      .then((res) => {
        let resultData = res?.data?.data?.result;

        setFilteredRow(
          resultData.reduce((acc, cur) => {
            return [
              ...acc,
              {
                ...cur,
                name: `${cur.lastName}, ${cur.firstName}`,
              },
            ];
          }, []),
        );
      })
      .catch((err) => console.log(err));
  };

  const handleWorklist = () => {
    dispatch(isFetchingPatientResults(false));
    dispatch(errorRetrievingPatientList(false));
    dispatch(errorMessageRetrieved(null));
    handleClickClose();
    dispatch(
      actions.getAudioData(
        tabPage === 0 ? INCOMING : COMPLETED,
        audioData?.pageSize,
        audioData?.page,
        searchValue,
      ),
    )
      .then((res) => {
        let resultData = res?.data?.data?.result;

        setFilteredRow(
          resultData.reduce((acc, cur) => {
            return [
              ...acc,
              {
                ...cur,
                name: `${cur.lastName}, ${cur.firstName}`,
              },
            ];
          }, []),
        );
      })
      .catch((err) => console.log(err));
  };

  const sortingFeature = (params) => {
    if (params.length > 0 && params[0].field === 'timeRequested') {
      dispatch(
        actions.getAudioData(
          tabPage === 0 ? INCOMING : COMPLETED,
          audioData?.pageSize,
          audioData?.page,
          searchValue,
          params[0].sort,
        ),
      )
        .then((res) => {
          let resultData = res?.data?.data?.result;

          setFilteredRow(
            resultData.reduce((acc, cur) => {
              return [
                ...acc,
                {
                  ...cur,
                  name: `${cur.lastName}, ${cur.firstName}`,
                },
              ];
            }, []),
          );
          setTabCount(tabPage === 0 ? res?.data?.data?.count : res?.data?.data?.totalCount);
        })
        .catch((err) => console.log(err));
    }
    setSortModel(params);
  };

  const handlePage = (newPage) => {
    if (newPage === 0)
      dispatch(
        changePage(
          audioData?.page === 1 ? 0 : audioData?.page !== 0 ? audioData?.page : audioData?.page + 1,
        ),
      );
    else dispatch(changePage(newPage));
  };
  const handlePageSize = (newPageSize) => {
    dispatch(changePageSize(newPageSize));
  };
  return (
    // Wrapper Layout of Table and Tabs
    <Box className={classes.container}>
      <Grid>
        <Grid>
          <Grid item style={{ marginTop: '10px' }}>
            {/* <MuiTypography
              fontSize="24px"
              fontFamily={fontFamily}
              lineHeight="28px"
              letterSpacing="0.25px"
              fontWeight={500}
              color={black}
            >
              {t(AUDIO_INBOX)}
            </MuiTypography>
            <BottomLineTitle bg={'#1976D2'} />
            <div className={classes.breadCrumbStyle}>
              {' '}
              <BasicBreadcrumbs
                data={[
                  { type: 'image', url: DOCTOR_ROUTES.TELEKARDIA_DASHBOARD, src: 'Home' },
                  { type: 'text', name: AUDIO_INBOX},
                ]}
              />
            </div> */}
            {(audioData?.showToast?.show || audioData?.error === true) &&
              !audioData?.errorMessage && (
                <Box className={classes.toastContainer}>
                  <Toast
                    open={audioData?.showToast || audioData?.error === true}
                    closeHandler={handleClose}
                    alertMessage={`${
                      audioData?.error === true
                        ? ERROR_MESSAGE
                        : `Report ${
                            audioData?.patient?.stethioPatientDetails?.reportId
                          } has been successfully ${
                            audioData?.showToast?.cancel === true ? 'cancelled' : 'submitted'
                          }.`
                    }`}
                    severity={`${audioData?.error === true ? 'error' : 'success'}`}
                  />
                </Box>
              )}
            <div className={classes.tabStyle}>
              <TabSelector
                count={audioData?.count}
                items={[
                  { item: INCOMING, showCount: true, active: tabPage === 0 },
                  { item: COMPLETED, showCount: false },
                ]}
                selected={tabPage}
                callback={getTabValue}
              />
            </div>
            <div className={classes.tableParent}>
              <MuiTypography
                fontSize="20px"
                fontFamily={fontFamily}
                lineHeight="32px"
                letterSpacing="0.15px"
                fontWeight={500}
                color={black}
              >
                {t('Audio Inbox')}
              </MuiTypography>
              <div className={classes.tableParent} style={{ background: 'white' }}>
                <Box className={classes.inputContainer}>
                  <FormControl className={classes.inputElement} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={'text'}
                      value={searchValue}
                      onChange={(e) => setSearchvalue(e.target.value)}
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          onSearchClick();
                          event.target.blur();
                        }
                      }}
                      // sx={{border:`1px solid #CCCCCC`}}
                      placeholder={t('Search by Report ID or Name')}
                      endAdornment={
                        <InputAdornment position="end" onClick={handleSearch}>
                          <IconButton aria-label="toggle password visibility" edge="end">
                            {<SearchIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Search"
                    />
                  </FormControl>
                  {isLoadingSearchResults && display ? (
                    <Paper elevation={6} className={classes.searchResultTray}>
                      <li className={classes.list}>
                        <Typography
                          component="h4"
                          className={`${classes.searchResultTrayText} ${classes.noResultFound}`}
                        >
                          Searching...
                        </Typography>
                      </li>
                    </Paper>
                  ) : (
                    ''
                  )}
                  {!isLoadingSearchResults && searchValue.length > 1 && display && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Paper elevation={6} className={classes.searchResultTray}>
                        <List>
                          {data && data.length > 0 ? (
                            data.map((data, index) => {
                              return (
                                <li
                                  className={`${classes.list} ${classes.clickable}`}
                                  key={index}
                                  onClick={(e) =>
                                    permissions?.isAdmin && tabPage === 0
                                      ? null
                                      : handleRowClick(e, data)
                                  }
                                >
                                  <Tooltip
                                    title={`${data.lastName}, ${data.firstName}`}
                                    classes={{ tooltip: classes.tooltip }}
                                  >
                                    <Typography
                                      component="h4"
                                      className={`${classes.searchResultTrayText} ${classes.searchResultTrayName}`}
                                    >
                                      {`${data?.lastName}, ${data?.firstName}`}
                                    </Typography>
                                  </Tooltip>
                                  <Typography
                                    component="p"
                                    className={classes.searchResultTrayText}
                                  >
                                    {data?.reportId}
                                  </Typography>
                                </li>
                              );
                            })
                          ) : !isLoadingSearchResults ? (
                            <li className={classes.list}>
                              <Typography
                                component="h4"
                                className={`${classes.searchResultTrayText} ${classes.noResultFound}`}
                              >
                                No Results Found
                              </Typography>
                            </li>
                          ) : (
                            ''
                          )}
                        </List>
                      </Paper>
                    </ClickAwayListener>
                  )}
                </Box>

                <div className={classes.audioTable}>
                  {audioData?.loading === true ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '60vh',
                      }}
                    >
                      <Loading />{' '}
                    </div>
                  ) : (
                    <MuiTableComponent
                      columns={columns}
                      rows={filteredRow ? filteredRow : []}
                      sortModel={sortModel}
                      noMargin={true}
                      loading={loading}
                      columnToolbar={true}
                      filterToolbar={false}
                      densityToolbar={true}
                      exportToolbar={false}
                      pagination
                      noColumnMargin={'-11px'}
                      pageSize={audioData?.pageSize}
                      onPageSizeChange={handlePageSize}
                      page={audioData?.page}
                      onPageChange={handlePage}
                      rowsPerPageOptions={perPageOptions}
                      paginationMode="server"
                      rowCount={nextMultipleOf10(tabCount)}
                      sortingFeature={sortingFeature}
                      columnVisibilityModel={columnVisibilityModel}
                      onColumnVisibilityModelChange={(newModel) =>
                        setColumnVisibilityModel(newModel)
                      }
                      ColumnResizeIcon={null}
                      setFilterModel={(val) => setFilterModel(val)}
                      onRowClick={
                        permissions?.isAdmin && tabPage === 0
                          ? null
                          : (params) => handleRowClick(params)
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {audioData?.errorMessage && (
        <AlertDialog
          open={audioData?.errorMessage}
          // onClickFirstButton={handleWorklist}
          onClickSecondButton={handleWorklist}
          // buttonNameFirst={RETURN_TO_WORKLIST}
          buttonSecondName={RETURN_TO_WORKLIST}
          handleClose={handleClickClose}
          title={audioData?.errorMessage}
          body={
            <p>
              {TECHNICAL_ERROR}
              <br />
              {''}
            </p>
          }
        />
      )}
    </Box>
  );
};

export default AudioScreen;
