export const EKG_COLOR = {
  NORMAL: '#6BC077',
  NORMAL_SINUS_RHYTHM: '#6BC077',
  OTHER: '#FDC246',
  TACHYCARDIA: '#FDC246',
  BRADYCARDIA: '#FDC246',
  HEART_RATE_OVER_120: '#FDC246',
  HEART_RATE_UNDER_50: '#FDC246',
  INCONCLUSIVE: '#FDC246',
  AFIB: '#F78C4E',
  SINUS_RHYTHM: '#F78C4E',
  SINUSRHYTHM: '#F78C4E',
  WIDE_QRS: '#F78C4E',
  'SINUS_RHYTHM,WIDE_QRS': '#F78C4E',
  NO_ANALYSIS: '#BABFBD',
  TOO_SHORT: '#BABFBD',
  TOO_LONG: '#BABFBD',
  UNREADABLE: '#BABFBD',
  INCONCLUSIVE_POOR_READING: '#BABFBD',
};
export const EKG_TYPE = {
  NORMAL: 'Normal',
  NORMAL_SINUS_RHYTHM: 'Normal',
  OTHER: 'Abnormal',
  TACHYCARDIA: 'Abnormal',
  BRADYCARDIA: 'Abnormal',
  HEART_RATE_OVER_120: 'Abnormal',
  HEART_RATE_UNDER_50: 'Abnormal',
  INCONCLUSIVE: 'Abnormal',
  AFIB: 'Others',
  SINUS_RHYTHM: 'Other',
  SINUSRHYTHM: 'Other',
  WIDE_QRS: 'Other',
  'SINUS_RHYTHM,WIDE_QRS': 'Others',
  NO_ANALYSIS: 'No Analysis',
  TOO_SHORT: 'No Analysis',
  TOO_LONG: 'No Analysis',
  UNREADABLE: 'No Analysis',
  INCONCLUSIVE_POOR_READING: 'No Analysis',
};

export const startPattern = 'YYYY-MM-DDT00:00:00.000';
export const endPattern = 'YYYY-MM-DDT23:59:00.000';

export const pdfWidth = 1300;
