export const AppointmentStatusTypes = {
  Scheduled: 1,
  Physician_Review: 2,
  InProgress: 3,
  Completed: 4,
  Cancelled: 5,
  Rescheduled: 6,
  Encounter_Summary_Preparation: 7,
  Missed: 8,
  Cancel_For_Reschedule: 9,
  closed: 10,
  Declined: 11,
  MemberInWaitingRoom: 12,
};
export const appointmentStatusName = {
  SCHEDULED: 'Scheduled',
  COMPLETED: 'Completed',
  INPROGRESS: 'InProgress',
  CLOSED: 'Closed ',
  WAITING_ROOM: 'Waiting-Room ',
  ENCOUNTER_SUMMARY: 'Encounter-Summary-Preparation',
};
export const intakePrograms = {
  HHA: 'heartHealthAssessment',
  PP: 'programPlan',
};
export const sessionSeverity = {
  LOW: '1',
  LOW_MEDIUM: '2',
  MEDIUM: '3',
  HIGH_MEDIUM: '5',
  HIGH: '5',
};
export const programMap = {
  'AfibAndHypertension-Id': 'Afib and HTN',
  'Afib-Id': 'Afib',
  'Hypertension-Id': 'HTN',
  'HeartHealth-Id': 'Heart Health',
};
export const deProgramMap = {
  'Afib and HTN': 'AfibAndHypertension-Id',
  Afib: 'Afib-Id',
  HTN: 'Hypertension-Id',
  'Heart Health': 'HeartHealth-Id',
};
export const AppointmentClosedReasons = {
  'Patient did not turn up': 1,
  "Technical issue at patient's end": 2,
  'Technical issue at my end': 3,
  'Patient declined consultation': 4,
};
export const AppointmentClosedReasonsType = {
  1: 'Patient did not turn up',
  2: "Technical issue at patient's end",
  3: 'Technical issue at my end',
  4: 'Patient declined consultation',
  5: 'System Closed',
};
export const sessionCompletedMethod = {
  AS_SCHEDULED_VIDEO: 1,
  AS_SCHEDULED_AUDIO: 2,
  ADHOC_SESSION: 3,
  OTHER: 4,
};
export const SessionCompletionMethodTextConversion = {
  AS_SCHEDULED_VIDEO: 'Video Call',
  AS_SCHEDULED_AUDIO: 'Audio Call',
  AS_SCHEDULED_PHONE: 'Phone Call',
  ADHOC_SESSION: 'Backup Call',
  OTHER: 'Other',
};
export const SessionChannelTypeId = {
  AS_SCHEDULED_VIDEO: 3,
  AS_SCHEDULED_PHONE: 1,
};
export const consultationType = {
  'Video Consult': 3,
  'Phone Consult': 1,
};

export const CancellationTypes = {
  Patient: 1,
  Physician: 2,
  Auto: 3,
};

export const HeartConditions = {
  Atrial_Fibrillation: 1,
  Bradycardia: 2,
  unreadable: 3,
  Tachycardia: 4,
  Sinus_Tach: 5,
};

export const ProviderStatus = {
  Available: 1,
  Offline: 2,
};

export const GENDER_FORMAT = {
  male: 'Male',
  female: 'Female',
  prefer_not_to_say: 'Prefer not to say',
};

export const USER_TEAM_TYPE = {
  SIMBA: 'SIMBA',
  CONSUMER: 'CONSUMER',
};
// password validation rules
export const checkNumberOfCharacher = (str) => str.length > 7 && str.length < 21;
export const checkUpperCase = (str) => /[A-Z]/.test(str);
export const checkLowerCase = (str) => /[a-z]/.test(str);
export const checkHasNumber = (str) => /\d/.test(str);

export const RegionList = [
  { name: 'ALABAMA', value: 'AL' },
  { name: 'ALASKA', value: 'AK' },
  { name: 'AMERICAN SAMOA', value: 'AS' },
  { name: 'ARIZONA', value: 'AZ' },
  { name: 'ARKANSAS', value: 'AR' },
  { name: 'CALIFORNIA', value: 'CA' },
  { name: 'COLORADO', value: 'CO' },
  { name: 'CONNECTICUT', value: 'CT' },
  { name: 'DELAWARE', value: 'DE' },
  { name: 'DISTRICT OF COLUMBIA', value: 'DC' },
  { name: 'FEDERATED STATES OF MICRONESIA', value: 'FM' },
  { name: 'FLORIDA', value: 'FL' },
  { name: 'GEORGIA', value: 'GA' },
  { name: 'GUAM', value: 'GU' },
  { name: 'HAWAII', value: 'HI' },
  { name: 'IDAHO', value: 'ID' },
  { name: 'ILLINOIS', value: 'IL' },
  { name: 'INDIANA', value: 'IN' },
  { name: 'IOWA', value: 'IA' },
  { name: 'KANSAS', value: 'KS' },
  { name: 'KENTUCKY', value: 'KY' },
  { name: 'LOUISIANA', value: 'LA' },
  { name: 'MAINE', value: 'ME' },
  { name: 'MARSHALL ISLANDS', value: 'MH' },
  { name: 'MARYLAND', value: 'MD' },
  { name: 'MASSACHUSETTS', value: 'MA' },
  { name: 'MICHIGAN', value: 'MI' },
  { name: 'MINNESOTA', value: 'MN' },
  { name: 'MISSISSIPPI', value: 'MS' },
  { name: 'MISSOURI', value: 'MO' },
  { name: 'MONTANA', value: 'MT' },
  { name: 'NEBRASKA', value: 'NE' },
  { name: 'NEVADA', value: 'NV' },
  { name: 'NEW HAMPSHIRE', value: 'NH' },
  { name: 'NEW JERSEY', value: 'NJ' },
  { name: 'NEW MEXICO', value: 'NM' },
  { name: 'NEW YORK', value: 'NY' },
  { name: 'NORTH CAROLINA', value: 'NC' },
  { name: 'NORTH DAKOTA', value: 'ND' },
  { name: 'NORTHERN MARIANA ISLANDS', value: 'MP' },
  { name: 'OHIO', value: 'OH' },
  { name: 'OKLAHOMA', value: 'OK' },
  { name: 'OREGON', value: 'OR' },
  { name: 'PALAU', value: 'PW' },
  { name: 'PENNSYLVANIA', value: 'PA' },
  { name: 'PUERTO RICO', value: 'PR' },
  { name: 'RHODE ISLAND', value: 'RI' },
  { name: 'SOUTH CAROLINA', value: 'SC' },
  { name: 'SOUTH DAKOTA', value: 'SD' },
  { name: 'TENNESSEE', value: 'TN' },
  { name: 'TEXAS', value: 'TX' },
  { name: 'UTAH', value: 'UT' },
  { name: 'VERMONT', value: 'VT' },
  { name: 'VIRGIN ISLANDS', value: 'VI' },
  { name: 'VIRGINIA', value: 'VA' },
  { name: 'WASHINGTON', value: 'WA' },
  { name: 'WEST VIRGINIA', value: 'WV' },
  { name: 'WISCONSIN', value: 'WI' },
  { name: 'WYOMING', value: 'WY' },
];

export const listItems = [
  { item: '0.5', show: false },
  { item: '0.75', show: false },
  { item: '1', show: true },
  { item: '1.25', show: false },
  { item: '1.5', show: false },
  { item: '1.75', show: false },
];
