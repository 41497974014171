import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Grid, Typography, Box, Button } from "@material-ui/core";

import { useStyles } from "./ClinicianInteractionInfoStyles";
import {setTabState} from "store/actions";

import { changeDateTimeFormat, getMeridiem } from "utilities/Utilities";
import { acuitySingleKeyMapper } from "constants/acuityMapper";
import { COMPLETTED, DOCTOR_ROUTES } from "constants/app";

function ClinicianInteractionInfo(props) {
  const { reviewDetail } = props;

  const profile = useSelector((state) => state.user.profile);

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  function onCloseHandler() {
    dispatch(setTabState(COMPLETTED));
    history.replace(DOCTOR_ROUTES.TELEKARDIA_ECG);
  }

  return (
    <React.Fragment>
      <Paper elevation={0} className={classes.root}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={5} className={classes.leftMostGridItem}>
            <Box
              className={`${classes.paddingBox} ${classes.baseRhyhtmContainer}`}
            >
              <Typography component="h4" className={classes.heading}>
                Base Rhythm:
              </Typography>
              <Typography
                className={`${classes.generalFont} ${classes.selectedItem}`}
              >
                {reviewDetail.rhythmName ? reviewDetail.rhythmName : "--"}
              </Typography>
            </Box>
            <Box className={classes.paddingBox}>
              <Typography component="h4" className={classes.heading}>
                Secondary Observations:
              </Typography>
              <Box className={classes.secondaryObservationDiv}>
                <Typography component="p">PVCs:</Typography>
                <Box className={classes.pillsContainer}>
                  <Button
                    className={
                      reviewDetail.pvcs === "None"
                        ? classes.selectedpill
                        : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                  >
                    None
                  </Button>
                  <Button
                    className={
                      reviewDetail.pvcs === "1 PVC"
                        ? classes.selectedpill
                        : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                  >
                    1 PVC
                  </Button>
                  <Button
                    className={
                      reviewDetail.pvcs === "2+ PVCs"
                        ? classes.selectedpill
                        : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                  >
                    2+ PVCs
                  </Button>
                </Box>
              </Box>
              <Box className={classes.secondaryObservationDiv}>
                <Typography component="p">PACs:</Typography>
                <Box className={classes.pillsContainer}>
                  <Button
                    className={
                      reviewDetail.pacs === "None"
                        ? classes.selectedpill
                        : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                  >
                    None
                  </Button>
                  <Button
                    className={
                      reviewDetail.pacs === "1 PAC"
                        ? classes.selectedpill
                        : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                  >
                    1 PAC
                  </Button>
                  <Button
                    className={
                      reviewDetail.pacs === "2+ PACs"
                        ? classes.selectedpill
                        : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                  >
                    2+ PACs
                  </Button>
                </Box>
              </Box>
              <Box className={classes.secondaryObservationDiv}>
                <Typography component="p">QRS:</Typography>
                <Box className={classes.pillsContainer}>
                  <Button
                    className={
                      reviewDetail.qrs === "Normal"
                        ? classes.selectedpill
                        : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                  >
                    Normal
                  </Button>
                  <Button
                    className={
                      reviewDetail.qrs === "Wide"
                        ? classes.selectedpill
                        : classes.pill
                    }
                    classes={{
                      root: classes.commonButton,
                    }}
                  >
                    Wide
                  </Button>
                </Box>
              </Box>
            </Box>
            {reviewDetail.avBlocks && reviewDetail.avBlocks.length > 0 && (
              <Box
                className={classes.secondaryObservationDiv}
                style={{ paddingLeft: "0.5rem" }}
              >
                <Typography
                  component="p"
                  className={`${classes.secondaryObservationDiv} ${classes.avBlockMargin}`}
                >
                  Av Blocks:
                </Typography>
                {reviewDetail.avBlocks &&
                  reviewDetail.avBlocks.sort().map((avBlock, i) => {
                    return (
                      <Typography
                        key={i}
                        className={`${classes.generalFont} ${classes.selectedItem} ${classes.avBlockMargin}`}
                      >
                        {avBlock}
                      </Typography>
                    );
                  })}
              </Box>
            )}
          </Grid>
          <Grid item xs={5} className={classes.notesAndButtonGrid}>
            <Box className={classes.contianerBox}>
              <Box>
                <Typography component="h4" className={classes.heading}>
                  Notes and Recommendations:
                </Typography>
                <Box className={classes.notesContainer}>
                  <Typography className={classes.generalFont}>
                    {reviewDetail.notes ? reviewDetail.notes : "--"}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box className={classes.acuityContainer}>
                  <Typography component="h4" className={classes.heading}>
                    Acuity
                  </Typography>
                  <Typography
                    className={`${classes.generalFont} ${classes.selectedItem}`}
                  >
                    {reviewDetail.acuityName
                      ? acuitySingleKeyMapper(reviewDetail.acuityName)
                      : "--"}
                  </Typography>
                </Box>
                <Box className={classes.reviewSubmittedByBox}>
                  <Typography
                    component="p"
                    className={classes.reviewSubmittedByText}
                  >
                    Report submitted on
                    {reviewDetail.timeSubmit
                      ? ` ${changeDateTimeFormat(
                          reviewDetail.timeSubmit
                        )} ${getMeridiem(reviewDetail.timeSubmit)} `
                      : "--"}
                    by Dr.
                    {reviewDetail.memberFirstName} {reviewDetail.memberLastName}
                    .
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.closeButtonContainer}>
              <Button
                onClick={onCloseHandler}
                className={`${classes.commonButtonStyles} ${classes.submitButton}`}
              >
                CLOSE
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default ClinicianInteractionInfo;
