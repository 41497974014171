import { CHAT_PAGINATION_COUNT } from 'constants/app';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  data: [],
  loadMoreCount: CHAT_PAGINATION_COUNT,
  chat: {}, // stores file upload success failure value
  pendingRequests: 0, // dynamic variable to track no. of pending requests and responses
  inboxLoadCount: 0,
  chatHistory: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CHAT_USERS_START:
      return {
        ...state,
        loading: true,
        data: [],
      };
    case actionTypes.GET_CHAT_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.messages,
        loadMoreCount: action.payload.loadMoreCount,
      };
    case actionTypes.GET_CHAT_USERS_FAIL:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.error,
      };
    case actionTypes.SET_CHAT_LOADER:
      return {
        ...state,
        inboxLoadCount: action.payload.count,
      };
    case actionTypes.LOAD_CHAT_HISTORY:
      return {
        ...state,
        chatHistory: action.payload,
      };
    case actionTypes.UPLOAD_FILE_START:
      return {
        ...state,
      };
    case actionTypes.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        chat: action.payload,
      };
    case actionTypes.UPLOAD_FILE_FAIL:
      return {
        ...state,
        chat: action.payload,
      };
    case actionTypes.IS_PENDING_REQUEST:
      return {
        ...state,
        pendingRequests: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
