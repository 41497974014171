/* eslint-disable */
import { combineReducers } from 'redux';
import UserReducer, { initialUserState } from './user';
import AppointmentsReducer from './dashboard';
import CalendarReducer from './calendar';
import EkgReducer from './ekg';
import AudioReducer from './audio';
import AudioearchReducer from './audioSearch';
import EkgListReducers from './EkgListReducers';
import EkgListTableReducers from './EkgListTableReducers';
import EkgReviewReducers from './EkgReviewReducers';
import EkgSearchReducers from './EkgSearchReducers';
import EkgReportReducers from './EkgReportReducers';
import SideBarReducer from './sidebar';
import ChatReducer from './chat';
import appReducer from './app';
import requestNewPasswordReducer from '../../screens/common/RequestNewPassword/module';
import { reducer as formReducer } from 'redux-form';
import { memberListReducer, memberProfileReducer } from './member';
import teamMemberInvitesReducer from 'components/TeamMemberInvite/module';
import sessionReducer from './session';
import { adminSessionsReducer } from './sessions';
import patientFileReducer from 'screens/coach/MemberProfile/components/Metrics/PatientFile/module';
import * as actionTypes from '../actions/actionTypes';
import { AdHocReducer } from './Adhoc';
import escalationReducer from './escalation';
import fullfilmentMemberListReducer from './fullfilment';
import settingsReducer from './settings';

const rootReducer = combineReducers({
  events: CalendarReducer,
  user: UserReducer,
  appointments: AppointmentsReducer,
  ekg: EkgReducer,
  audio: AudioReducer,
  requestNewPassword:requestNewPasswordReducer,
  audioSearch: AudioearchReducer,
  ekgList: EkgListReducers,
  ekgListTable: EkgListTableReducers,
  ekgReview: EkgReviewReducers,
  ekgSearch: EkgSearchReducers,
  ekgReport: EkgReportReducers,
  sidebar: SideBarReducer,
  chat: ChatReducer,
  app: appReducer,
  memberList: memberListReducer,
  memberProfile: memberProfileReducer,
  form: formReducer,
  teamMemberInvite: teamMemberInvitesReducer,
  session: sessionReducer,
  sessions: adminSessionsReducer,
  patientFile: patientFileReducer,
  adhocCall: AdHocReducer,
  escalation: escalationReducer,
  fullfilment: fullfilmentMemberListReducer,
  settings: settingsReducer,
});
export default (state, action) => {
  switch (action.type) {
    case actionTypes.USER_LOGOUT:
      return rootReducer(undefined, action);
    default:
      return rootReducer(state, action);
  }
};
