import { Box, Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import AsyncChat from 'components/Chat/AsyncChat';
import { SectionMain } from 'components/mui/sections/SectionMain';

const Messages = (props) => {
  const { programData: data } = useSelector((state) => state.memberProfile);
  return (
    <SectionMain
      headerElement={{
        headerText: 'Messages',
      }}
      bodyElement={[
        {
          childElements: (
            <Grid item lg={12} md={12} style={{ borderLeft: '1px solid #e9e9e9' }}>
              <Box style={{ marginTop: 10 }}>
                {data.chatUserID && (
                  <AsyncChat
                    memberName={`${data.participantFirstName} ${data.participantLastName}`}
                    chatUserId={data.chatUserID.toLowerCase()}
                    getS3BucketData={null}
                    upLoadFiles={props.upLoadFiles}
                    uploadFileSuccess={props.uploadFileSuccess}
                  />
                )}
              </Box>
            </Grid>
          ),
        },
      ]}
    />
  );
};

export default Messages;
