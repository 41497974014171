import { makeStyles } from "@material-ui/core/styles";
import {
  white,
  platinumSmoke,
  dustySmoke,
  bluishGrey,
  darkBluishGrey,
  lightGreen,
  pineGreen,
  snowWhite,
  fullBlack,
} from "../../../style/colors";
import { fontFamily } from "../../../style/commonStyles";

export const useStyles = makeStyles(() => ({
  topbar: {
    background: white,
    height: "66px",
    padding: "4px 4px",
    borderLeft: `1px solid ${platinumSmoke}`,
    borderRight: `1px solid ${platinumSmoke}`,
    position: "relative",
    marginTop: "0.2rem",
    paddingTop: "1rem",
    display:"flex",
    justifyContent:"space-between"
  },
  searchBarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputContainer: {
    position: "relative",
    width: "95%",
  },
  list: {
    "&:hover": {
      backgroundColor: `${lightGreen} !important`,
    },
    fontFamily: `${fontFamily} !important`,
    height: "56px",
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  tooltip: {
    fontFamily,
    background: snowWhite,
    color: darkBluishGrey,
    fontSize: "14px",
    border: ".5px solid #999999",
    textTransform: "capitalize",
  },
  inputElement: {
    border: `2px solid ${dustySmoke}`,
    color: darkBluishGrey,
    borderRadius: "4px",
    marginLeft: "0.5rem",
    padding: "0.3rem",
    width: "95%",
    fontFamily,
    fontSize: "16px",
    lineHeight: "20px",
  },
  inputBase: {
    color: fullBlack,
  },
  searchResultTray: {
    maxHeight: "300px",
    overflow: "auto",
    position: "absolute",
    width: "inherit",
    left: "8px",
    zIndex: 4,
  },
  searchResultTrayText: {
    fontFamily,
    paddingLeft: "24px",
    fontSize: "16px",
    lineHeight: "20px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "30%",
    textTransform: "capitalize",
  },
  searchResultTrayName: {
    fontWeight: "bold",
  },
  clickable: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  noResultFound: {
    fontWeight: "bold",
  },
  searchButton: {
    fontFamily,
    color: white,
    fontWeight: "bold",
    fontSize: "14px",
    padding: "12px 20px",
    width: "99px",
    backgroundColor: pineGreen,
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: pineGreen,
    },
  },
  refreshIcon: {
    backgroundColor: bluishGrey,
    color: darkBluishGrey,
    width: "32px",
    height: "32px",
    borderRadius: "8px",
  },
  cancelIcon: {
    color: darkBluishGrey,
    height: "20px",
    width: "20px",
  },
  searchTabSeperator: {
    paddingTop: "1rem",
    borderLeft: `1px solid ${platinumSmoke}`,
    borderRight: `1px solid ${platinumSmoke}`,
  },
}));
