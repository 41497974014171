export const CRP_SERVICE = [
  {
    id: '1',
    serviceName: 'Clinician Review',
  },
  {
    id: '2',
    serviceName: 'Audio Review',
  },
];

export const REPORT_TYPE_MENU_OPTIONS = [
  {
    id: '1',
    reportType: 'Completed Reviews',
  },
  {
    id: '2',
    reportType: 'Current Reviews in Queue',
  },
  {
    id: '3',
    reportType: 'Current Overdue Reviews',
  },
  {
    id: '4',
    reportType: 'Reviews Returned Late',
  },
];

export const CRP_REGION = "crpRegion";

export const NO_REPORT_MESSAGE = "There’s no data for the selected report. Please select different filters to export report.";

export const REPORT_ERROR_MESSAGE = "Something went wrong! Can’t Generate Report Right Now. Try Again Later!";

export const reportTypeConst= {
  COMPLETED:"Completed",
  QUEUE:"Current Reviews in Queue",
  OVERDUE:"Overdue",
  COMPLETED_OVERDUE:"CompletedOverdue"
}

export const reportTitleConst= {
  COMPLETED:"Completed Review",
  QUEUE:"Current Reviews in Queue",
  OVERDUE:"Current Overdue Reviews",
  COMPLETED_OVERDUE:"Reviews Returned Late"
}