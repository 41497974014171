import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
const LicenseExpiryColumn = (item) => {
  const [iconColor, setIconColor] = useState('');
  const [columnText, setColumnText] = useState('');

  useEffect(() => {
    if (moment(item.params?.expiryDate).diff(moment()) < 0) {
      setIconColor('tomato');
      setColumnText('Expired');
    } else if (moment(item.params?.expiryDate).diff(moment(), 'days') < 90) {
      setIconColor('gold');
      setColumnText('Expiring soon');
    } else setIconColor('transparent');
  }, [item]);

  return (
    <Grid container>
      <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
        <ReportProblemIcon style={{ fill: iconColor }} />
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body1">{columnText}</Typography>
        <Typography variant="body1">
          {moment(item.params.expiryDate).format('L') || '--'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LicenseExpiryColumn;
