import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AutoCompleteDropdown from 'components/AutoCompleteDropdown';
import { AUTH_TYPE } from 'constants/app';
import { useSelector } from 'react-redux';
import { ChatContext } from 'providers/chat';
import { USER_TEAM_TYPE } from 'utilities/Constants';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '20px',
  width: '100%',
  fontFamily: 'Work Sans',
  '& .MuiTypography-subtitle2': {
    fontWeight: 600,
    fontFamily: 'Work Sans',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14px',
  },
}));
const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  fontFamily: 'Work sans',
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(2),
    // borderBottomRightRadius: theme.spacing(2),

    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      // backgroundColor: theme.palette.action.focus,
      backgroundColor: 'unset',
      border: '1px solid rgba(0, 0, 0, 0.3)',
      borderRadius: '30px',
    },
    [`&.Mui-selected > &.MuiTreeItem-label > &.MuiBox-root > &.MuiTypography-body2`]: {
      fontWeight: 700,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: '400',
      color: '1px solid rgba(0, 0, 0, 0.87)',
    },
    '& .MuiTypography-body2': {
      lineHeight: '175%',
      letterSpacing: '0.15px',
      fontFamily: 'Work Sans',
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    setActive,
    changeMsgCount,
    ...other
  } = props;
  const changetab = (id) => {
    id != '4' && setActive(id);
    id == '9' && changeMsgCount();
  };
  return (
    <StyledTreeItemRoot
      onClick={() => changetab(other.nodeId)}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 1, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 0.5 }} />
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo != 0 && labelInfo}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export default function SideNav(props) {
  const userType = useSelector((state) => state.user.chatProfile.userType);
  const { programData } = useSelector((state) => state.memberProfile);
  const permissions = useSelector((state) => state.user.profile.permissions);

  const [msgCount, setMsgCount] = React.useState(0);

  const { unreadMsgCount, setUnreadMsgCount } = React.useContext(ChatContext);
  React.useEffect(() => {
    if (programData) {
      let count = unreadMsgCount[programData?.chatUserID?.toLowerCase()];
      setMsgCount((c) => (count ? count : 0));
    }
  }, [programData, unreadMsgCount]);

  const changeMsgCount = () => {
    unreadMsgCount[programData?.chatUserID?.toLowerCase()] &&
      setUnreadMsgCount((d) => {
        d[programData?.chatUserID?.toLowerCase()] = 0;
        return d;
      });
  };

  return (
    <Root>
       {/* This logic is written to Show the Globalserach only for Coach users- ASP-318 */}
      {userType == "Coach" &&
      <div style={{ marginBottom: '20px' }}>
        <Typography style={{ marginBottom: '10px' }} variant="subtitle2">
          Member Select
        </Typography>
      <AutoCompleteDropdown />
      </div>}  
      <Typography style={{ marginBottom: '10px' }} variant="subtitle2">
        Member Chart
      </Typography>
      <TreeView
        aria-label="gmail"
        defaultSelected={
          props.appointmentId != ' ' && props.appointmentId != undefined ? ['1'] : ['2']
        }
        defaultExpanded={['3']}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        sx={{ height: 'auto', flexGrow: 1, maxWidth: '100%', overflowY: 'auto' }}
      >
        {props.appointmentId != ' ' && props.appointmentId != undefined && (
          <StyledTreeItem nodeId="1" labelText="This Session" {...props} />
        )}
        <StyledTreeItem nodeId="2" labelText="Program" {...props} />
        {programData?.userType === USER_TEAM_TYPE.SIMBA && (
          <>
            <StyledTreeItem nodeId="3" labelText="Health Details" {...props} />
            <StyledTreeItem nodeId="4" labelText="Metrics" {...props}>
              <StyledTreeItem
                nodeId="5"
                labelText="EKG"
                // labelIcon={SupervisorAccountIcon}
                color="#1a73e8"
                bgColor="#e8f0fe"
                {...props}
              />
              <StyledTreeItem
                nodeId="6"
                labelText="Blood Pressure"
                color="#e3742f"
                bgColor="#fcefe3"
                {...props}
              />
              <StyledTreeItem
                nodeId="7"
                labelText="Weight"
                color="#a250f5"
                bgColor="#f3e8fd"
                {...props}
              />
            </StyledTreeItem>
            <StyledTreeItem nodeId="8" labelText="Activity/Notes" {...props} />
          </>
        )}
        {userType === AUTH_TYPE.COACH && !permissions?.isAdmin && (
          <StyledTreeItem
            nodeId="9"
            labelText="Messages"
            labelInfo={msgCount}
            changeMsgCount={changeMsgCount}
            {...props}
          />
        )}
      </TreeView>
    </Root>
  );
}
