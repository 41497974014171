import React, { useEffect, useState } from 'react';

import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Checkbox,
  Stack,
} from '@mui/material';
import { styled } from '@mui/system';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { listItems } from 'utilities/Constants';

const CustomMenuItem = styled(MenuItem)`
  padding: 0 5px;
`;

export default function MenuListComposition(props) {
  const [selectedValue, setselectedValue] = useState(1);

  useEffect(() => {
    setselectedValue(props.setValue);
  }, [props.setValue]);

  const updatedListItems = listItems.map((item) => {
    if (item.item == selectedValue) {
      return { ...item, show: true };
    } else {
      return { ...item, show: false };
    }
  });

  const handleChange = (event, item) => {
    props.getValue(item.item);
  };

  const handleClose = (event) => {
    if (props.anchorRef.current && props.anchorRef.current.contains(event.target)) {
      return;
    }

    props.setOpen.call(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      props.setOpen(false);
    } else if (event.key === 'Escape') {
      props.setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(props.open);
  React.useEffect(() => {
    if (prevOpen.current === true && props.open === false) {
      props.anchorRef.current.focus();
    }

    prevOpen.current = props.open;
  }, [props.open]);
  return (
    <Stack direction="row" spacing={2}>
      <div>
        {/* <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          Dashboard
        </Button> */}
        <Popper
          open={props.open}
          anchorEl={props.anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={props.open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {updatedListItems?.map((item, i) => {
                      return (
                        <CustomMenuItem key={i}>
                          <Checkbox
                            checked={item?.show}
                            onChange={(event) => handleChange(event, item)}
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<RadioButtonCheckedIcon />}
                          />
                          {item?.item === '1' ? 'Normal' : `${item?.item}x`}
                        </CustomMenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
