import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Paper, Box, Typography } from '@material-ui/core';

import WarningIcon from '../../../assets/images/Vector.png';
import CustomModal from 'components/Modals/CustomModal';
import CustomTable from 'components/Tables/Table/CustomTable';
import EcgLoader from '../../Shared/EcgLoader';
import { tableStyles } from './EkgListTableStyles';
import { columns } from '../../../constants/columnLabels';
import { REVIEW_IN_PROGRESS_ERROR_CODE } from 'constants/errorCodes';
import {
  getAvailabData,
  checkReviewStatus,
  resetIsOpenReviewClicked,
  setIsErrorGettingPatientData,
} from 'store/actions';
import { COMPLETTED, INCOMMING, PAGE_LIMIT } from 'constants/app';
import { EkgHeaders } from 'utilities/Utilities';

const EkgListTable = (props) => {
  const classes = tableStyles();
  const [isScrollTop, setIsScrollTop] = useState(false);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [timeRequested, setTimeRequested] = useState(true);
  const [timeSubmitted, setTimeSubmitted] = useState(false);
  const [isReviewInProgress, setIsReviewInProgress] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isShowReviewClicked, setIsShowReviewClicked] = useState(false);
  const [patientId, setPatientId] = useState(1);

  const profile = useSelector((state) => state.user.profile);
  const tabState = useSelector((state) => state.ekgList.tabState);
  const dataRedux = useSelector((state) => state.ekgListTable.ekglist);
  const isLoadingData = useSelector((state) => state.ekgListTable.isLoadingData);
  const total = useSelector((state) => state.ekgListTable.totalCount);
  const noData = useSelector((state) => state.ekgListTable.noData);
  const incomingListDataNotAvailable = useSelector(
    (state) => state.ekgListTable.errorRetrievingIncomingData,
  );
  const completedListDataNotAvailable = useSelector(
    (state) => state.ekgListTable.errorRetrievingCompletedData,
  );
  const reviewInProgress = useSelector((state) => state.ekgListTable.reviewInProgress);
  const isOpenReviewClicked = useSelector((state) => state.ekgListTable.isOpenReviewClicked);
  const errorGettingPatientData = useSelector((state) => state.ekgReview.errorGettingPatientData);
  const isSearchButtonClicked = useSelector((state) => state.ekgSearch.isSearchButtonClicked);
  const isCancelButtonClicked = useSelector((state) => state.ekgSearch.isCancelClicked);

  const dispatch = useDispatch();
  const history = useHistory();
  const isAdmin = profile.permissions.isAdmin;

  let overdue = (
    <Box width={90} height={20} className={`${classes.statusColumn} ${classes.overdue}`}>
      OVERDUE
    </Box>
  );
  let inprogress = (
    <Box width={117} height={24} className={`${classes.statusColumn} ${classes.inProgress}`}>
      IN PROGRESS
    </Box>
  );
  let notStarted = (
    <Box width={117} height={24} className={`${classes.statusColumn} ${classes.notStarted}`}>
      NOT STARTED
    </Box>
  );
  let completed = (
    <Box width={117} height={24} className={`${classes.statusColumn} ${classes.completed}`}>
      COMPLETED
    </Box>
  );

  useEffect(() => {
    dispatch(getAvailabData(PAGE_LIMIT, 0, timeRequested, '', tabState, timeSubmitted));
  }, [tabState]);

  useEffect(() => {
    if (isOpenReviewClicked) {
      if (reviewInProgress) {
        setIsReviewInProgress(true);
        return;
      }
      if (isAdmin) {
        return;
      }
      if (tabState === INCOMMING) {
        history.push(`/clinician/ecglist/review/${patientId}/incoming`);
        return;
      }
      history.push(`/clinician/ecglist/review/${patientId}/completed`);
    }
    return () => {
      dispatch(resetIsOpenReviewClicked());
      setIsShowReviewClicked(false);
    };
  }, [isOpenReviewClicked, reviewInProgress]);

  useEffect(() => {
    if (errorGettingPatientData === REVIEW_IN_PROGRESS_ERROR_CODE) {
      setIsReviewInProgress(true);
      dispatch(setIsErrorGettingPatientData(''));
      return;
    }
    dispatch(setIsErrorGettingPatientData(''));
  }, [errorGettingPatientData]);

  useEffect(() => {
    if (dataRedux) {
      setData([...data].concat(dataRedux));
    }
    setIsScrollTop(false);
  }, [dataRedux, total]);

  useEffect(() => {
    document.getElementsByClassName('infinite-scroll-component')[0].scrollTop = 0;
    setIsScrollTop(true);
    if (props.enteredSearchString.length === 0) {
      return;
    }
    setSearchTerm(props.enteredSearchString);
    setData([]);
    setPage(0);
    dispatch(
      getAvailabData(
        PAGE_LIMIT,
        0,
        timeRequested,
        props.enteredSearchString,
        tabState,
        timeSubmitted,
      ),
    );
  }, [props.enteredSearchString]);

  useEffect(() => {
    if (props.isEmptyTableData) {
      setData([]);
      setSearchTerm('');
      setIsScrollTop(true);
      dispatch(getAvailabData(PAGE_LIMIT, 0, true, '', tabState, false));
    }
  }, [props.isEmptyTableData]);

  useEffect(() => {
    document.getElementsByClassName('infinite-scroll-component')[0].scrollTop = 0;
    setIsScrollTop(true);
    setIsShowReviewClicked(false);
    if (props.refresh === 1 || isLoadingData) {
      return;
    }
    setData([]);
    setTimeRequested(true);
    setTimeSubmitted(false);
    setSearchTerm('');
    setPage(0);
    dispatch(getAvailabData(PAGE_LIMIT, 0, true, '', tabState, false));
  }, [props.refresh]);

  useEffect(() => {
    setData([]);
    document.getElementsByClassName('infinite-scroll-component')[0].scrollTop = 0;
    setTimeRequested(true);
    setTimeSubmitted(false);
    setSearchTerm('');
    setPage(0);
  }, [tabState]);

  const fetchMoreData = () => {
    if (isScrollTop || isSearchButtonClicked || isShowReviewClicked) {
      return;
    }
    setPage((prev) => prev + PAGE_LIMIT);
    dispatch(
      getAvailabData(
        PAGE_LIMIT,
        page + PAGE_LIMIT,
        timeRequested,
        searchTerm,
        tabState,
        timeSubmitted,
      ),
    );
  };

  const changedFilter = (label) => {
    if (data.length === 0) {
      return;
    }
    document.getElementsByClassName('infinite-scroll-component')[0].scrollTop = 0;
    setIsShowReviewClicked(false);
    setData([]);
    setPage(0);
    setIsScrollTop(true);
    if (label === 'Time Requested' || label === 'Time Left') {
      setTimeRequested((prev) => !prev);
      dispatch(getAvailabData(PAGE_LIMIT, 0, !timeRequested, searchTerm, tabState, timeSubmitted));
      return;
    }
    setTimeSubmitted((prev) => !prev);
    dispatch(getAvailabData(PAGE_LIMIT, 0, timeRequested, searchTerm, tabState, !timeSubmitted));
  };

  function transformTimeLeft(timeLeft) {
    timeLeft = timeLeft.toUpperCase();
    const index = timeLeft.indexOf(' ');
    const timeLeftNumber = +timeLeft.slice(0, index).trim();
    if (timeLeftNumber < 0) {
      let exclamation = <Box className={classes.timeLeftExclamation}>!</Box>;

      let timeLeftBox = (
        <Box className={classes.timeLeftNegative}>
          <span>
            {timeLeft}
            {exclamation}
          </span>
        </Box>
      );
      return timeLeftBox;
    }
    let timeLeftBox = <Box className={classes.timeLeft}>{timeLeft}</Box>;
    return timeLeftBox;
  }

  const sortIcon = (column) => {
    if (column === 'Time Requested') {
      return timeRequested ? 'asc' : 'desc';
    } else if (column === 'Time Left') {
      return timeRequested ? 'desc' : 'asc';
    } else {
      return timeSubmitted ? 'desc' : 'asc';
    }
  };

  const noResultCondition = !!noData && !!props.enteredSearchString.length;
  const allCaughtUpCondition = tabState === INCOMMING && noData && total == 0 && searchTerm.length == '';
  const allCaughtUpConditionCompletedTab =
    tabState === COMPLETTED && noData && total == 0 && searchTerm.length == '';

  const openReview = (id) => {
    if (isAdmin && tabState === INCOMMING) {
      return;
    }
    if (isAdmin && tabState === COMPLETTED) {
      history.push(`/admin/clinician/ecglist/review/${id}/completed`);
      return;
    }
    if (isShowReviewClicked) {
      return;
    }
    if (tabState === COMPLETTED) {
      history.push(`/clinician/ecglist/review/${id}/completed`);
      return;
    }
    setIsShowReviewClicked(true);
    dispatch(checkReviewStatus(id));
    setPatientId(id);
  };

  const hideReviewInProgressModal = () => {
    setIsReviewInProgress(false);
    setIsShowReviewClicked(false);
    let updatedStatusData = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === patientId) {
        data[i].status = 'IN PROGRESS';
      }
    }
    updatedStatusData = data;
    setData(updatedStatusData);
  };

  return (
    <React.Fragment>
      {isShowReviewClicked && (
        <Box className={classes.showReviewLoader}>
          <EcgLoader />
        </Box>
      )}
      <Paper>
        <CustomTable
          tableHeaders={EkgHeaders(tabState)}
          sortIcon={sortIcon}
          changedFilter={changedFilter}
          transformTimeLeft={transformTimeLeft}
          sortLabel1="Time Left"
          sortLabel2="Time Submitted"
          tab={tabState}
          totalData={total}
          tableBodyData={data}
          fetchMoreData={fetchMoreData}
          isShowReviewClicked={isShowReviewClicked}
          isScrollTop={isScrollTop}
          isSearchButtonClicked={isSearchButtonClicked}
          isCancelButtonClicked={isCancelButtonClicked}
          allCaughtUpCondition={allCaughtUpCondition}
          allCaughtUpConditionCompletedTab={allCaughtUpConditionCompletedTab}
          noResultCondition={noResultCondition}
          incomingListDataNotAvailable={incomingListDataNotAvailable}
          completedListDataNotAvailable={completedListDataNotAvailable}
          isLoadingData={isLoadingData}
          onClick={openReview}
          isNotClickable={isAdmin && tabState === INCOMMING}
          statusOverdue="OVERDUE"
          StatusInProgress="IN PROGRESS"
          StatusNotStarted="NOT STARTED"
          statusCompleted="COMPLETED"
          statusOverdueComponent={overdue}
          StatusInProgressComponent={inprogress}
          StatusNotStartedComponent={notStarted}
          statusCompletedComponent={completed}
          tab1NoDataText="You're all caught up!"
          tab2NoDataText="You haven’t completed any reviews."
          incomingListDataNotAvailableMessage="Incoming List not available at the moment!"
          completedListDataNotAvailableMessage="Completed List not available at the moment!"
        />
      </Paper>
      {isLoadingData &&
        document.getElementsByClassName('infinite-scroll-component')[0].scrollTop == 0 && (
          <Box style={{ position: 'absolute', top: '40%', left: '50%' }}>
            <EcgLoader />
          </Box>
        )}
      {noResultCondition && !isLoadingData && (
        <Typography className={classes.noResult}>No results</Typography>
      )}
      <CustomModal
        modalType="alert"
        open={isReviewInProgress}
        closeModalHandler={hideReviewInProgressModal}
        icon={WarningIcon}
        iconAltText="Warning Icon"
        heading="Review In Progess"
        descriptionLine1="The report is being reviewed by another physician."
        confirmHandler={hideReviewInProgressModal}
        confirmButtonText="CLOSE"
      />
    </React.Fragment>
  );
};

export default EkgListTable;