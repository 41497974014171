import * as React from 'react';
import { GoAPI, KccGoAPI } from 'Axios/axios';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import { GridRowModes, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';

import { MuiTableComponent } from 'screens/common/CustomUI';
import { SectionMain } from 'components/mui/sections/SectionMain';
import TeamAlert from 'components/Shared/Alert/TeamAlert';
import ModalComponent from 'components/Common/modal/ModalComponent';
import LicenseExpiryColumn from 'screens/common/CustomUI/Table/components/LicenseExpiryColumn';
import { getToken } from 'auth/AuthUtilities';
import { RegionList } from 'utilities/Constants';
import { COMPONENT_USAGE } from '../constant/Constant';
import { useSelector } from 'react-redux';
import { AUTH_TYPE } from 'constants/app';

export default function License(props) {
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [showAlert, setShowAlert] = React.useState({
    data: '',
    error: false,
  });
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const [confirmRevokeLicenseState, setconfirmRevokeLicenseState] = React.useState(false);
  const [confirmRevokeLicenseParameter, setConfirmRevokeLicenseParameter] = React.useState(false);

  const { isAdmin } = useSelector((state) => state.user.profile.permissions);

  const columns = [
    {
      field: 'id',
      headerName: '#',
      width: 50,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: 'licenseType',
      headerName: 'License Type',
      width: 200,
      editable: props.usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS ? false : true,
      type: 'singleSelect',
      valueOptions: ['Medical'],
    },
    {
      field: 'licenseNumber',
      headerName: 'License Number',
      type: 'string',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      editable: props.usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS ? false : true,
    },
    {
      field: 'licenseState',
      headerName: 'License State',
      width: 220,
      editable: props.usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS ? false : true,
      type: 'singleSelect',
      valueOptions: RegionList.map((item) => item.value),
    },
    {
      field: 'expiryDate',
      headerName: 'License Expiry',
      type: 'date',
      width: 250,
      editable: props.usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS ? false : true,
      renderCell: (params) => <LicenseExpiryColumn params={params?.row} />,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      hide: props.usage === COMPONENT_USAGE.INDIVIDUAL_PROVIDER_SETTINGS,
      width: 50,
      cellClassName: 'actions',
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.row.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={1}
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(params.row)}
              showInMenu
            />,
            <GridActionsCellItem
              key={2}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.row)}
              color="inherit"
              showInMenu
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key={1}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.row)}
            color="inherit"
            showInMenu
            disabled={!isAdmin}
          />,
          <GridActionsCellItem
            key={2}
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params.row)}
            color="inherit"
            showInMenu
            disabled={!isAdmin}
          />,
        ];
      },
    },
  ];

  React.useEffect(() => {
    props.usage !== COMPONENT_USAGE.USER_INVITE && getLicenseTableInfo();
  }, []);

  const getLicenseTableInfo = () => {
    setLoading(true);
    KccGoAPI.get(`i/v1/members/${props.userId}/license`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        setRows(res.data || []);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const deleteLicenseTableInfo = (id) => {
    setLoading(true);
    KccGoAPI.delete(`i/v1/members/${props.userId}/license/${id}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        setRows(rows.filter((row) => row.id !== id));
        setconfirmRevokeLicenseState(false);
        setLoading(false);
        setShowAlert({
          data: 'License deleted successfully',
          error: false,
        });
        setTimeout(() => {
          setShowAlert({ data: '', type: null });
        }, 5000);
      })
      .catch((err) => console.log(err));
  };
  const addLicenseTableInfo = (row) => {
    setLoading(true);

    let data = {
      ...row,
      // memberId: props.userId,
      added: true,
      isEditable: true,
      key: rows.length.toString(),
    };
    delete data.id;
    delete data.isNew;
    delete data.issuedDate;

    GoAPI.post(`i/v1/members/${props.userId}/license`, data, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        getLicenseTableInfo();
        setShowAlert({
          data: 'A new license has been added successfully',
          error: false,
        });
        setTimeout(() => {
          setShowAlert({ data: '', type: null });
        }, 5000);
        setLoading(false);
      })
      .catch((err) => {
        getLicenseTableInfo();
        setShowAlert({
          data: 'Could not add the license, please fill all fields',
          error: true,
        });
        setTimeout(() => {
          setShowAlert({ data: '', type: null });
        }, 5000);
        setLoading(false);
      });
  };
  const editLicenseTableInfo = (row) => {
    setLoading(true);
    let data = [
      {
        ...row,
        issuedDate: new Date(),
        memberId: props.userId,
        added: false,
      },
    ];
    KccGoAPI.put(`i/v1/members/${props.userId}/license`, data, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        setShowAlert({
          data: 'Selected license has been edited successfully',
          error: false,
        });
        setTimeout(() => {
          setShowAlert({ data: '', type: null });
        }, 5000);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick =
    ({ id }) =>
    () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

  const handleSaveClick = (row) => () => {
    setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick =
    ({ id }) =>
    () => {
      confirmRemoveLicense(id);
    };

  const handleCancelClick =
    ({ id }) =>
    () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    };

  const confirmRemoveLicense = React.useCallback((liceneseId) => {
    setconfirmRevokeLicenseState(true);
    setConfirmRevokeLicenseParameter(liceneseId);
  }, []);

  const cancleDeleteLicense = () => {
    setconfirmRevokeLicenseState(false);
  };
  const processRowUpdate = (newRow) => {
    console.log(newRow);
    typeof newRow.id === 'number' ? addLicenseTableInfo(newRow) : editLicenseTableInfo(newRow);
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleCustomToolbarClick = () => {
    const id = Math.random(1, 9);
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        licenseType: '',
        licenseNumber: '',
        licenseState: '',
        issuedDate: '',
        expiryDate: '',
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'licenseType' },
    }));
  };

  return (
    <>
      <SectionMain
        // layout={{ margin: '10px 0' }}
        headerElement={{
          headerText: 'License Details',
        }}
        bodyElement={[
          {
            childElements: (
              <>
                {showAlert.data !== '' && (
                  <TeamAlert title={showAlert.data} error={showAlert.error} />
                )}
                <MuiTableComponent
                  sx={{ margin: 'unset !important' }}
                  rows={rows}
                  columns={columns}
                  initialState={{
                    // filter: {
                    //   filterModel: initialFilter,
                    // },
                    sorting: {
                      sortModel: [{ field: 'expiryDate', sort: 'asc' }],
                    },
                  }}
                  editMode="row"
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  // components={{
                  //   toolbar: EditToolbar,
                  // }}
                  componentProps={{
                    toolbar: { setRows, setRowModesModel },
                  }}
                  loading={loading}
                  columnToolbar={false}
                  filterToolbar={false}
                  densityToolbar={false}
                  exportToolbar={false}
                  customToolbar={isAdmin && true}
                  customToolbarText={'Add License'}
                  handleCustomToolbarClick={handleCustomToolbarClick}
                  pagination
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  page={page}
                  onPageChange={(newPage) => setPage(newPage)}
                  rowsPerPageOptions={[10, 20, 50]}
                  paginationMode="server"
                  setFilterModel={() => {}}
                />
              </>
            ),
          },
        ]}
      />
      <ModalComponent
        headerText={'Revoke License?'}
        modalText={'Are you sure you want to revoke this License?'}
        open={confirmRevokeLicenseState}
        handleClose={cancleDeleteLicense}
        cancelButtonText={'cancel'}
        confirmButtonText={'Yes, Delete'}
        confirmHandler={deleteLicenseTableInfo}
        confirmPayload={confirmRevokeLicenseParameter}
      />
    </>
  );
}
