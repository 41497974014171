import { updateObject } from "store/utility";
import * as types from "../actions/actionTypes";
import { INCOMMING } from "constants/app";

const initialState = {
  tabState: INCOMMING,
};

const setTabState = (state, action) => {
  return updateObject(state, {
    tabState: action.payload,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SETTABSTATE:
      return setTabState(state, action);
    default:
      return state;
  }
};

export default reducer;
