import React, { useEffect, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import PageUpdated from 'components/PageUpdated';
import { parseName } from 'utilities/Utilities';
import { getMemberProfileDetails, getMemberWeightDetails } from 'store/actions/memberProfile';
import SkeletonLoader from 'components/Loading/SkeletonLoader';

const Root = styled('div')(({ theme }) => ({
  '& .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    '&:nth-child(n + 2)': {
      marginLeft: '32px',
    },
  },
  '& .MuiTypography-body2': {
    fontFamily: 'Work Sans',
    fontWeight: 400,
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiTypography-subtitle2': {
    marginLeft: '16px',
    fontFamily: 'Work Sans',
    fontWeight: 600,
    fontSize: '16px',
    background: 'white',
    padding: '7px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));
function Header({ refreshAction = null, ...props }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const { programData: data, programLoading } = useSelector((state) => state.memberProfile);
  const { me } = useSelector((state) => state.app.dataSources);
  const [weightInput, setWeightInput] = useState(null);

  useEffect(() => {
    dispatch(getMemberProfileDetails(memberId));
    getMemberWeightDetails(memberId).then((res) => {
      setWeightInput(res);
    });
    return () => setWeightInput(null);
  }, [memberId]);

  const goBack = () => {
    history.goBack();
  };
  return (
    <Root>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {props.postSession && (
          <ArrowBackIosNewIcon
            style={{ marginRight: 10, color: '#6387C5', fontSize: 25 }}
            onClick={goBack}
          />
        )}
        <SkeletonLoader isloading={programLoading}>
          <Typography style={{ fontSize: '20px', fontFamily: 'Work Sans' }}>
            <b>{parseName(data?.participantFirstName, data?.participantLastName)}</b>
            {/* todo- add gender here in the header */}
            <span
              style={{
                width: '35px',
                height: '6px',
                borderRadius: '8px',
                backgroundColor: '#6387c5',
                display: 'block',
                margin: '10px 0',
              }}
            />
          </Typography>
        </SkeletonLoader>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Box>
            <Typography variant="body2">MRN</Typography>
            <SkeletonLoader isloading={programLoading}>
              <Typography variant="subtitle2">--</Typography>
            </SkeletonLoader>
          </Box>
          <Box>
            <Typography variant="body2">DOB</Typography>
            <SkeletonLoader isloading={programLoading}>
              <Typography variant="subtitle2">
                {data?.participantDateOfBirth
                  ? moment(data?.participantDateOfBirth).format('MM-DD-YYYY')
                  : '--'}
              </Typography>
            </SkeletonLoader>
          </Box>
          <Box>
            <Typography variant="body2">AGE</Typography>
            <SkeletonLoader isloading={programLoading}>
              <Typography variant="subtitle2">
                {`${moment().diff(data?.participantDateOfBirth, 'years')} yrs`}
              </Typography>
            </SkeletonLoader>
          </Box>
          <Box>
            <Typography variant="body2">SEX</Typography>
            <SkeletonLoader isloading={programLoading}>
              <Typography variant="subtitle2">
                {/* {data?.gender && GENDER_FORMAT[data?.gender]} */}
                {data?.gender}
              </Typography>
            </SkeletonLoader>
          </Box>
          <Box>
            <Typography variant="body2">WEIGHT</Typography>
            <SkeletonLoader isloading={weightInput === null}>
              <Typography variant="subtitle2">
                {weightInput?.answers?.weightValue || data?.weight || '--'}
              </Typography>
            </SkeletonLoader>
          </Box>
        </div>
        <div style={{ alignSelf: 'baseline', marginRight: '30px' }}>
          <PageUpdated
            isLoading={programLoading}
            lastUpdated={data?.lastRefreshed}
            handleOnClick={() => refreshAction()}
            hasTabs={true}
            me={me}
          />
        </div>
      </div>
    </Root>
  );
}

export default Header;
