import * as actionTypes from './actionTypes';

export const toggleHeader = (text, isSideBar) => ({
  type: actionTypes.SETTINGS_HEADER_CHANGE,
  headerText: text,
  isSidebar: isSideBar,
});

export const handletoggleHeader = (text, isSideBar) => (dispatch, getState) => {
  dispatch(toggleHeader(text, isSideBar));
};
