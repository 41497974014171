import { Grid, Typography, styled } from '@mui/material';
import React from 'react';
import classes from './Section.module.css';

const Root = styled('div')(({ theme }) => ({
  '& .MuiTypography-body2': {
    fontSize: '12px',
    fontWeight: 400,
    marginTop: 'unset !important',
    color: 'rgb(0, 0, 0, 0.6)',
    fontFamily: 'Work Sans',
  },
  '& .MuiTypography-h6': {
    fontSize: '20px',
    fontWeight: 500,
    color: 'rgb(0, 0, 0, 0.6)',
    fontFamily: 'Work Sans',
    lineHeight: '160%',
  },
}));

const SectionHeader = ({ icon = null, headerText, helperText = null, action = null, ...props }) => {
  return (
    <Root>
      <Grid container className={classes.headerContainer}>
        {headerText && (
          <div style={{ display: 'flex', columnGap: '10px' }}>
            {icon != null && icon}
            <Typography variant="h6" className={classes.headerText}>
              {headerText}
            </Typography>
          </div>
        )}
        <div>
          {helperText != null && (
            <Typography variant="body2" style={{ color: 'red' }}>
              {helperText}
            </Typography>
          )}
          {action != null && action}
        </div>
        {props?.subheaderText && (
          <Grid item xs={12}>
            <Typography variant="body2">{props?.subheaderText}</Typography>
          </Grid>
        )}
        {/* {props?.childElements && ( */}
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          {props?.childElements}
        </Grid>
        {/* )} */}
      </Grid>
    </Root>
  );
};

export default SectionHeader;
