import { updateObject } from "store/utility";
import * as types from "../actions/actionTypes";

const initialState = {
  ekglist: [],
  isLoadingData: false,
  totalCount: 0,
  noData: false,
  timenow: "",
  errorRetrievingIncomingData: false,
  errorRetrievingCompletedData: false,
  reviewInProgress: false,
  isOpenReviewClicked: false,
  notstartedAndOverdueCount: 0,
};

export const reviewInProgressStatus = (state, action) => {
  return updateObject(state, {
    reviewInProgress: action.payload,
    isOpenReviewClicked: true,
  });
};

const resetIsOpenReviewClicked = (state, action) => {
  return updateObject(state, {
    isOpenReviewClicked: false,
  });
};

export const errorRetrievingIncomingList = (state, action) => {
  return updateObject(state, {
    errorRetrievingIncomingData: action.payload,
  });
};

export const errorRetrievingCompletedList = (state, action) => {
  return updateObject(state, {
    errorRetrievingCompletedData: action.payload,
  });
};

const emptyDataRedux = (state) => {
  return updateObject(state, {
    ekglist: [],
    totalCount: 0,
  });
};

const getDataredux = (state, action) => {
  return updateObject(state, {
    ekglist: action.payload.result,
    totalCount: action.payload.totalCount,
    noData: action.payload.totalCount == 0,
    timenow: Date.now(),
  });
};

const isFetching = (state, action) => {
  return updateObject(state, {
    isLoadingData: action.payload,
  });
};

const getOverdueAndNotStartedCount = (state, action) => {
  return updateObject(state, {
    notstartedAndOverdueCount: action.payload,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GETDATA_SUCCESS:
      return getDataredux(state, action);
    case types.ISFETCHING:
      return isFetching(state, action);
    case types.RESET_REDUX_DATA:
      return emptyDataRedux(state, action);
    case types.ERRORRETRIEVINGINCOMINGLIST:
      return errorRetrievingIncomingList(state, action);
    case types.ERRORRETRIEVINGCOMPLETEDLIST:
      return errorRetrievingCompletedList(state, action);
    case types.ISREVIEWINPROGRESS:
      return reviewInProgressStatus(state, action);
    case types.RESETISOPENREVIEWCLICKED:
      return resetIsOpenReviewClicked(state, action);
    case types.GET_OVERDUE_AND_NOTSTARTED_COUNT:
      return getOverdueAndNotStartedCount(state, action);
    default:
      return state;
  }
};

export default reducer;
