import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";
import {
  mintCream,
  white,
  dustyWhite,
  darkSlateGrey,
  pineGreen,
  pantoneRed,
  darkBluishGrey,
  black,
} from "../../style/colors";
import { fontFamily } from "../../style/commonStyles";
export const GreenCheckbox = withStyles({
  root: {
    color: "#B4BBC0",
    "&$checked": {
      color: pineGreen,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const useStyles = makeStyles({
  root: {
    backgroundColor: mintCream,
    height: "100%",
    fontFamily,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    height: "inherit",
  },
  leftMostGridItem: {
    paddingLeft: "0.5rem",
  },
  rootBox: {
    padding: "0.5rem",
  },
  leftPadding: {
    paddingLeft: "3rem",
  },
  heading: {
    fontFamily,
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
    marginBottom: "0.5rem",
    wordBreak: "break-word",
  },
  selectBoxContainer: {
    marginBottom: "0.5rem",
  },
  buttonHeading: {
    color: black,
  },
  buttonPillContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  commonButton: {
    textTransform: "capitalize",
  },
  pill: {
    fontFamily,
    fontSize: "14px",
    lineHeight: "16px",
    background: dustyWhite,
    borderRadius: "100px",
    padding: "0.5rem",
    textAlign: "center",
    fontWeight: "normal",
    marginRight: "0.5rem",
    marginTop: "0.5rem",
  },
  selectedpill: {
    fontFamily,
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "bold",
    background: darkSlateGrey,
    borderRadius: "100px",
    padding: "0.5rem",
    textAlign: "center",
    marginRight: "0.5rem",
    marginTop: "0.5rem",
    color: white,
    "&:hover": {
      background: darkSlateGrey,
    },
  },
  secondaryObservationDiv: {
    marginBottom: "1rem",
    "& > p": {
      fontFamily,
    },
    "& > button": {
      fontFamily,
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  errorinputBox: {
    backgroundColor: white,
    border: `1px solid ${pantoneRed}`,
  },
  notesLimit: {
    fontFamily,
    textAlign: "end",
    color: pantoneRed,
    fontSize: "14px",
    lineHeight: "16px",
  },
  notesGridContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  notesAcuityContainer: {
    minHeight: "340px",
  },
  errorState: {
    minHeight: "400px",
  },
  acuityContainer: {
    marginTop: "0.5rem",
  },
  buttonContainer: {
    display: "flex",
    marginTop: "1rem",
    paddingBottom: "0.5rem",
  },
  commonButtonStyles: {
    fontFamily,
    width: "49%",
    height: "40px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
  },
  cancelButton: {
    backgroundColor: mintCream,
    color: pineGreen,
    border: `1px solid ${pineGreen}`,
    marginRight: "0.5rem",
  },
  submitButton: {
    backgroundColor: pineGreen,
    color: white,
    "&:hover": {
      backgroundColor: pineGreen,
    },
  },
  errorMessage: {
    fontSize: "14px",
    fontFamily,
    lineHeight: "16px",
    color: pantoneRed,
    marginTop: "0.5rem",
  },
  avFirstBlock: {
    width: "40%",
  },
  avSecondBlock: {
    width: "50%",
  },
  avBlockText: {
    fontFamily,
    color: darkBluishGrey,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18px",
    letterSpacing: "0.25px",
  },
  avBlockContainer: {
    flexDirection: "row",
    width: "100%",
    marginLeft: "1rem",
  },
  topMargin: {
    marginTop: "1rem",
  },
  avBlockError: {
    marginTop: 0,
    paddingLeft: "0.5rem",
  },
});
