import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import axios from 'axios';
import { Grid, Box, Typography } from '@material-ui/core';
import BottomLineTitle from '../../Shared/Typography/BottomLineTitle';
import MuiTypography from '../../Shared/Typography/MuiTypography';
import EkgPatientInformation from '../EkgPatientInformation/EkgPatientInformation';
import ClinicianInteraction from '../../ClinicianInteraction/ClinicianInteraction';
import ClinicianInteractionInfo from '../../ClinicianInteractionInfo/Clinicianinteractioninfo';
import PatientGraphInfo from '../../PatientGraphInfo/PateintGraphInfo';
import EcgLoader from '../../Shared/EcgLoader';
import { useStyles } from './EkgReviewStyles';
import { black } from '../../../style/colors';
import { fontFamily } from '../../../style/commonStyles';
import EkgRecording from 'components/chart/EkgRecording';
import { getEKGAlgorithmDeterminationDisplayText } from 'constants/recordingMaps';
import { getPatientData, emptyPatientData } from 'store/actions';
import { DOCTOR_ROUTES, FEMALE, LOADING_MESSAGE, MALE, UNKNOWN } from 'constants/app';
import { COMPLETED } from 'constants/audio';

const EkgReview = () => {
  const classes = useStyles();
  const { id, completed } = useParams();
  let key = 1;
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.user.profile);
  const patientData = useSelector((state) => state.ekgReview.patientInfo);
  const isLoadingPatientData = useSelector((state) => state.ekgReview.isLoadingPatientData);
  const recordingSample = useSelector((state) => state.ekgReview.recordingSample);
  const reviewDetail = useSelector((state) => state.ekgReview.reviewDetail);
  const errorGettingPatientData = useSelector((state) => state.ekgReview.errorGettingPatientData);
  const isSubmittingReview = useSelector((state) => state.ekgReview.isSubmittingReview);

  const isPhysician = profile.permissions.isPhysician;
  const isAdmin = profile.permissions.isAdmin;

  // Get patient Detail Info
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    dispatch(getPatientData(id, completed, source.token));
    return () => {
      source.cancel();
      dispatch(emptyPatientData());
    };
  }, [dispatch, id]);

  return (
    <React.Fragment>
      {isLoadingPatientData && (
        <Box className={`${classes.container} ${classes.loadingContainer}`}>
          <Box>
            <Typography>{LOADING_MESSAGE}</Typography>
          </Box>
        </Box>
      )}
      {Object.keys(patientData).length !== 0 && !isLoadingPatientData && (
        <Box className={classes.container}>
          {isSubmittingReview && (
            <Box className={classes.submittingReviewLoader}>
              <EcgLoader />
            </Box>
          )}
          <Grid container>
            <Grid item className={classes.topMargin}>
              <Box style={{ marginBottom: '0.1rem' }}>
                <MuiTypography
                  fontSize="24px"
                  fontFamily={fontFamily}
                  lineHeight="28px"
                  letterSpacing="0.25px"
                  fontWeight={500}
                  color={black}
                  textTransform="capitalize"
                >
                  {`${patientData.lastName}, ${patientData.firstName}`}
                </MuiTypography>
              </Box>
              <BottomLineTitle />
            </Grid>
            <Grid item xs={12} className={classes.topMargin}>
              <Box className={classes.divider}></Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <EkgPatientInformation
                dob={patientData.dob}
                sex={patientData.sex === 'M' ? (MALE ? patientData.sex === 'F' : FEMALE) : UNKNOWN}
                weight={patientData.weight}
                height={patientData.height}
                language={patientData.language}
                symptoms={
                  patientData.symptoms
                    ? patientData.symptoms
                        .reduce((str, ele) => (str += ele + ', '), '')
                        .slice(0, -2)
                    : ''
                }
                patientnotes={patientData.patientNotes ? patientData.patientNotes : ''}
              />
            </Grid>
            <Grid item xs={7}>
              {reviewDetail && reviewDetail?.status !== COMPLETED.toUpperCase() && isPhysician ? (
                <ClinicianInteraction
                  patientId={id}
                  reportId={patientData.reportId}
                  reviewDetail={reviewDetail}
                />
              ) : reviewDetail && reviewDetail?.status === COMPLETED.toUpperCase() ? (
                <ClinicianInteractionInfo patientId={id} reviewDetail={reviewDetail} />
              ) : (
                <Redirect to={DOCTOR_ROUTES.ADMIN_ECGLIST} replace={true} />
              )}
            </Grid>
          </Grid>
          <PatientGraphInfo
            reportId={patientData.reportId}
            interval={patientData.interval}
            interpretation={getEKGAlgorithmDeterminationDisplayText(
              patientData.interpretation,
              patientData.algorithmPackage,
              +patientData.heartRate,
            )}
            bpm={patientData.heartRate}
            recordedAt={patientData.recordedAt}
          />
          {recordingSample && (
            <EkgRecording
              key={key++}
              height={700}
              width={1500}
              recording={recordingSample}
              recordingSample={recordingSample}
              rowSeconds={15}
              calpulse={true}
              inverted={recordingSample.inverted} // using inverted flag from api response.
              is6l={Object.keys(recordingSample.samples).length === 6}
              duration={patientData?.duration}
            />
          )}
        </Box>
      )}
      {errorGettingPatientData && isPhysician && (
        <Redirect to={DOCTOR_ROUTES.TELEKARDIA_ECG} replace={true} />
      )}
      {errorGettingPatientData && isAdmin && (
        <Redirect to={DOCTOR_ROUTES.ADMIN_ECGLIST} replace={true} />
      )}
    </React.Fragment>
  );
};

export default EkgReview;
