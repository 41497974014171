/* eslint-disable import/no-unresolved */
import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import SectionBody from './SectionBody';
import SectionFooter from './SectionFooter';
import SectionHeader from './SectionHeader';
import classes from './Section.module.css';

export const SectionMain = (props) => {
  const { headerElement = {}, bodyElement = [], footerElement = {}, layout = {} } = props;
  return (
    <Paper className={classes.mainSection} sx={layout}>
      <SectionHeader
        icon={headerElement?.icon}
        headerText={headerElement?.headerText}
        helperText={headerElement?.helperText}
        subheaderText={headerElement?.subheaderText}
        action={headerElement?.action}
        childElements={headerElement?.childElements}
      />

      {bodyElement &&
        bodyElement.map((item, index) => (
          <Box key={index}>
            <SectionBody
              layout={item?.bodyLayout}
              headerText={item?.headerText}
              subheaderText={item?.subheaderText}
              headerElement={item.headerElement}
              childElements={item?.childElements}
            />
          </Box>
        ))}
      <SectionFooter {...footerElement} buttonInputProps={footerElement?.buttonInputProps} />
    </Paper>
  );
};
