import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Button, Grid, Paper, Typography, styled } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import CloseIcon from '@mui/icons-material/Close';

import NexmoClient from 'nexmo-client';
import { Resizable } from 're-resizable';
import Draggable from 'react-draggable';
import { useStopwatch } from 'react-timer-hook';

import styles from './adhoc.module.css';
import * as API from 'Services/API/actions';
import { closeAdhocCall, updateNotesId } from 'store/actions';
import AddEditNotes from 'screens/coach/MemberProfile/components/Documentation/AddEditNotes';
import { colors, fonts, fontSizes } from 'style/styleSettings';
import { getNotesMetadata } from 'store/actions/memberProfile';
import * as constant from 'constants/app';
import { renewAuth } from 'auth/AuthUtilities';
import { AppointmentStatusTypes, USER_TEAM_TYPE } from 'utilities/Constants';
import { getTimezoneName } from 'utilities/Utilities';
import ModalComponent from 'components/Common/modal/ModalComponent';
import { buttonText, callProgressConfirmationText, callType } from './constants/audioCallConstant';

const Root = styled('div')(({ theme }) => ({
  width: 'auto',
  backgroundColor: '#F1F2F3',
  boxShadow: '1px 2px lightgrey',
  padding: '15px 15px 30px',
  textAlign: 'center',
  '& .MuiTypography-h4': {
    fontSize: fontSizes.LARGE,
    fontFamily: fonts.SESSION,
    fontWeight: 500,
  },
  '& .MuiTypography-h6': {
    fontSize: fontSizes.HEADING,
    fontFamily: fonts.SESSION,
  },
  '& .MuiTypography-h5': {
    fontSize: fontSizes.BASE,
    fontFamily: fonts.SESSION,
  },
}));

function AdhocCallWindow() {
  const dispatch = useDispatch();
  const history = useHistory();
  const timerRef = useRef(null);

  const [callProgress, setProgress] = useState(constant.CALL_MEMBER);
  const [vonageApp, setvonageApp] = useState(null);
  const [callStatus, setcallStatus] = useState(null);
  const [noteId, setNoteId] = useState(null);
  const [notePostMessage, setnotePostMessage] = useState({});
  const [dragState, setDragState] = useState(0);

  const { memberId, isNote, appointmentId, memberType } = useSelector((state) => state.adhocCall);
  const { userType } = useSelector((state) => state.user.chatProfile);
  const teamId = useSelector((state) => state.user.team.id);
  const profileId = useSelector((state) => state.user.profile.id);
  const profileData = useSelector((state) => state.memberProfile.programData);
  const { programData } = useSelector((state) => state.memberProfile);

  const { seconds, minutes, start, pause } = useStopwatch({ autoStart: false });

  const renewRef = useRef(null);
  const renewAuthDuringApp = useCallback(() => {
    renewRef.current = setInterval(() => {
      dispatch(renewAuth());
    }, 600000);
  }, []);

  useEffect(() => {
    if (!renewRef.current) {
      renewAuthDuringApp();
    }
    dispatch(getNotesMetadata(teamId, profileId, false));
    return () => {
      hangupCall();
      clearInterval(renewRef.current);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(notePostMessage).length > 0 && notePostMessage?.success) {
      timerRef.current = setTimeout(() => {
        handleClose();
      }, 3000);
    }
    return () => clearTimeout(timerRef.current);
  }, [notePostMessage]);

  const initiateCall = () => {
    setProgress(constant.INITIATING_CALL);
    let body = { toId: memberId };
    isNote || memberType === USER_TEAM_TYPE.CONSUMER
      ? API.getVonageToken(teamId, profileId, body).subscribe(
          (res) => {
            VonageInit(res.data.result.token, res.data.result.id);
          },
          (error) => {
            console.log(error);
          },
        )
      : API.getSessionToken(teamId, profileId).subscribe((res) => {
          VonageInit(res.data.result);
        });
  };

  useEffect(() => {
    if (vonageApp !== null) {
      switchHandler();
    }
  }, [vonageApp]);

  const callMetrics = (conversationId) => {
    API.fallBack_CallMetrics(teamId, profileId, appointmentId, conversationId).subscribe(
      (res) => {
        //update callMetrics response
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const VonageInit = (token, callId = '') => {
    new NexmoClient({ debug: true })
      .createSession(token)
      .then((app) => {
        setvonageApp(app);
        switchHandler();
        app.on('member:call', (member, call) => {
          setcallStatus(call);
          setmember(member);
          !isNote && memberType !== USER_TEAM_TYPE.CONSUMER && callMetrics(call.conversation.id);
          app.getConversation(call.conversation.id).then((conversation) => {
            conversation.on('member:left', (event) => {
              call.hangUp();
            });
          });
        });
        app.on('call:status:changed', (call) => {
          console.log(call.status);
          if (call.status === call.CALL_STATUS.STARTED) setProgress(constant.CONNECTING);
          else if (call.status === call.CALL_STATUS.RINGING) setProgress('Ringing');
          else if (
            call.status === call.CALL_STATUS.CANCELLED ||
            call.status === call.CALL_STATUS.BUSY ||
            call.status === call.CALL_STATUS.FAILED ||
            call.status === call.CALL_STATUS.TIMEOUT ||
            call.status === call.CALL_STATUS.REJECTED
          ) {
            setProgress(constant.NOT_ANSWERED);
            call.hangUp();
          } else if (call.status === call.CALL_STATUS.ANSWERED) {
            setProgress(constant.CALL_IN_PROGRESS);
            start();
            let adhocBody = { started: true }; // body for adhoc call

            // body for session audio call call
            let audioCallbody = {
              appointmentStatus: AppointmentStatusTypes.InProgress,
              timeZoneOffset: new Date().getTimezoneOffset(),
              timeZone: getTimezoneName(),
            };
            isNote || memberType === USER_TEAM_TYPE.CONSUMER
              ? API.updateCallStatus(teamId, profileId, callId, adhocBody).subscribe(
                  () => {},
                  (error) => {
                    console.log(error);
                  },
                )
              : API.updateAppointmentStatus(
                  teamId,
                  profileId,
                  appointmentId,
                  audioCallbody,
                ).subscribe(
                  () => {},
                  () => {},
                );
          } else if (call.status === call.CALL_STATUS.COMPLETED) {
            setProgress(constant.CALL_COMPLETED);
            call.hangUp();
            pause();
            let body = { started: false };
            let audioCallbody = {
              appointmentStatus: AppointmentStatusTypes.Encounter_Summary_Preparation,
              timeZoneOffset: new Date().getTimezoneOffset(),
              timeZone: getTimezoneName(),
            };
            isNote || memberType === USER_TEAM_TYPE.CONSUMER
              ? API.updateCallStatus(teamId, profileId, callId, body).subscribe(
                  (res) => {
                    setNoteId(res.data.result);
                    dispatch(updateNotesId(res.data.result));
                  },
                  (error) => {
                    console.log(error);
                  },
                )
              : API.updateAppointmentStatus(
                  teamId,
                  profileId,
                  appointmentId,
                  audioCallbody,
                ).subscribe(
                  () => {},
                  () => {},
                );
          } else {
            setProgress(constant.NOT_ANSWERED);
            isNote &&
              API.callNotConnectedFallback(teamId, profileId, callId).subscribe(
                (res) => {
                  setNoteId(res.data.result);
                },
                (error) => console.log(error),
              );
            call.hangUp();
          }
        });
        // debugger;
      })
      .catch((err) => console.log(err));
  };
  const switchHandler = () => {
    let number = `${
      profileData?.ParticipantCountryCode
    }${profileData?.ParticipantPhoneNumber.replace(/[^\w\s]/gi, '')}`;
    if (vonageApp === null) return;
    if (callProgress === constant.INITIATING_CALL) {
      // backend service will decide voice call voiceover using custom data.. below are the voiceover
      // based on call type and other configs -->
      //  SIMBA
      // AdHoc - This call is from your KardiaComplete Care Team
      // Doctor - This call is for your scheduled cardiology consultation
      // Coach - This call is for your scheduled coaching session
      // CONSUMER
      // AdHoc - This call is from your Kardia Care Team
      // Doctor - This call is for your scheduled cardiology consultation
      // DEFAULT
      // This call is from your AliveCor Care Team
      let custom_data = {
        callType: appointmentId == null ? callType.ADHOC : callType.APPOINTMENT,
        patientId: memberId,
        appointmentId: appointmentId,
      };
      vonageApp.callServer(number, 'phone', custom_data);
    } else if (
      callProgress === constant.CONNECTING ||
      callProgress === constant.RINGING ||
      callProgress === constant.CALL_IN_PROGRESS
    ) {
      endcallconfirmationpopup && setendcallconfirmationpopup(false);
      callStatus.hangUp();
      pause();
    }
  };
  const hangupCall = () => {
    if (vonageApp !== null) callStatus.hangUp();
  };

  const handleClose = () => {
    dispatch(closeAdhocCall());
  };

  const onStart = () => {
    setDragState(dragState + 1);
  };

  const onStop = () => {
    setDragState(dragState - 1);
  };
  const dragHandlers = { onStart: onStart, onStop: onStop };

  const completeSession = () => {
    handleClose();
    if (userType === constant.AUTH_TYPE.COACH)
      history.push(
        `${constant.COACH_ROUTES.MEMBER_PROFILE_BASE}/${memberId}/${appointmentId}/true`,
      );
    else
      history.push(
        `${constant.DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${memberId}/${appointmentId}/true`,
      );
  };

  const completeSessionForUnansweredCall = () => {
    let audioCallbody = {
      appointmentStatus: AppointmentStatusTypes.Encounter_Summary_Preparation,
      timeZoneOffset: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
    };
    API.updateAppointmentStatus(teamId, profileId, appointmentId, audioCallbody).subscribe(
      (res) => {
        console.log(res);
        completeSession();
      },
      () => {},
    );
  };

  //call end confirmation trigger
  const [endcallconfirmationpopup, setendcallconfirmationpopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const endCallConfirmationTrigger = (event) => {
    setendcallconfirmationpopup(true);
    setAnchorEl(event.currentTarget);
  };
  const continueSessionConfirmationClick = () => {
    setendcallconfirmationpopup(false);
  };

  //toggle audio mute and unmute
  const [member, setmember] = React.useState(null);
  const [publishAudio, setPublishAudio] = useState(false);
  const toggleAudio = () => {
    if (!publishAudio) {
      member.mute(true);
      setPublishAudio(true);
    } else {
      member.mute(false);
      setPublishAudio(false);
    }
  };

  return ReactDOM.createPortal(
    <Root>
      <Draggable
        handle="p"
        {...dragHandlers}
        bounds={{ top: -200, left: -500, right: 700, bottom: 600 }}
      >
        <Paper className={styles.mainContainer}>
          <Resizable
            defaultSize={{
              width: '560px',
              height: endcallconfirmationpopup ? '300px' : 'auto',
            }}
            style={{ margin: '-10px -10px -20px', padding: '10px', overflow: 'hidden' }}
          >
            <Grid container>
              <Grid item xs={12}>
                {Object.keys(notePostMessage).length > 0 &&
                  (notePostMessage.success ? (
                    <Typography
                      variant="h5"
                      style={{
                        color: colors.SPRUCE,
                        fontSize: fontSizes.MEDIUM,
                        fontWeight: 700,
                        marginBottom: '10px',
                      }}
                    >
                      Notes are saved successfully
                    </Typography>
                  ) : (
                    <Typography
                      variant="h5"
                      style={{
                        color: colors.ERROR_RED,
                        fontSize: fontSizes.MEDIUM,
                        fontWeight: 700,
                        marginBottom: '10px',
                      }}
                    >
                      Notes could not saved due to error.
                    </Typography>
                  ))}
              </Grid>
              <p style={{ cursor: 'move', width: '100%' }}>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: 'left',
                    borderBottom: '1px solid #B2B6B2',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // marginBottom: '15px',
                    paddingBottom: '10px',
                  }}
                >
                  <Typography variant="h4">{callProgress}</Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      columnGap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    {callProgress === constant.CALL_IN_PROGRESS && (
                      <>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <Typography variant="h6">
                            {minutes < 10 ? <span>0{minutes}</span> : <span>{minutes}</span>}:
                            {seconds < 10 ? <span>0{seconds}</span> : <span>{seconds}</span>}
                          </Typography>
                        </div>
                        <Button variant="outlined" onClick={toggleAudio}>
                          {publishAudio ? <MicOffIcon /> : <MicIcon />}
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={endCallConfirmationTrigger}
                        >
                          {buttonText.HANGUP}
                        </Button>
                        <ModalComponent
                          headerText={'Confirm'}
                          modalText={'Would you like to end the session'}
                          open={endcallconfirmationpopup}
                          handleClose={continueSessionConfirmationClick}
                          cancelButtonText={'No, Continue'}
                          confirmButtonText={'Yes, Hangup'}
                          confirmHandler={switchHandler}
                        />
                      </>
                    )}
                    {!isNote &&
                      callProgress === constant.CALL_COMPLETED &&
                      memberType !== USER_TEAM_TYPE.CONSUMER && (
                        <>
                          {/* <Typography variant="h6">Complete session notes</Typography> */}
                          <Button
                            style={{ marginLeft: '10px' }}
                            variant="contained"
                            onClick={completeSession}
                          >
                            {buttonText.COMPLETE_SESSION_NOTES}
                          </Button>
                        </>
                      )}

                    {/* Redial option to be reflected for unanswered and completed calls */}
                    {!isNote &&
                      (callProgress === constant.NOT_ANSWERED ||
                        callProgress === constant.CALL_COMPLETED) && (
                        <Button variant="contained" color="success" onClick={initiateCall}>
                          {buttonText.REDIAL}
                        </Button>
                      )}
                    {!isNote &&
                      callProgress === constant.NOT_ANSWERED &&
                      memberType !== USER_TEAM_TYPE.CONSUMER && (
                        <Button
                          style={{ marginLeft: '10px' }}
                          variant="contained"
                          onClick={completeSessionForUnansweredCall}
                        >
                          {buttonText.COMPLETE_SESSION_NOTES}
                        </Button>
                      )}
                    {callProgress === constant.NOT_ANSWERED ||
                    (callProgress === constant.CALL_COMPLETED &&
                      memberType === USER_TEAM_TYPE.CONSUMER) ? (
                      <CloseIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
                    ) : null}
                  </div>
                </Grid>
              </p>
              {isNote && programData?.userType === USER_TEAM_TYPE.SIMBA ? (
                <Grid item xs={12}>
                  {callProgress === constant.NOT_ANSWERED ||
                  callProgress === constant.CALL_IN_PROGRESS ||
                  callProgress === constant.CALL_COMPLETED ? (
                    <div style={{ paddingBottom: '10px', marginTop: '-15px' }}>
                      <AddEditNotes
                        callProgress={callProgress}
                        type={constant.ADHOC_CALL}
                        noteId={noteId}
                        memberId={memberId}
                        setnotePostMessage={setnotePostMessage}
                        callDuration={`${minutes}min ${seconds}sec`}
                      />
                    </div>
                  ) : (
                    <Paper
                      style={{
                        backgroundColor: 'white',
                        borderRadius: 5,
                        padding: '10px',
                      }}
                    >
                      <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                          <Typography variant={'h4'}>
                            {callProgress === constant.CALL_MEMBER
                              ? callProgressConfirmationText.CONFIRM_CALLING
                              : callProgressConfirmationText.CALLING}
                          </Typography>
                          <Typography variant={'h4'}>
                            {`${profileData?.participantLastName}, ${profileData?.participantFirstName}`}
                            {callProgress === constant.CALL_MEMBER ? `?` : `...`}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            columnGap: '10px',
                          }}
                        >
                          {callProgress === constant.CALL_MEMBER && (
                            <Button variant="outlined" color="success" onClick={handleClose}>
                              {buttonText.CANCEL_CALL}
                            </Button>
                          )}
                          {(callProgress === constant.INITIATING_CALL ||
                            callProgress === constant.CONNECTING ||
                            callProgress === constant.CALL_MEMBER) && (
                            <Button
                              variant="contained"
                              color={callProgress === constant.CALL_MEMBER ? 'success' : 'error'}
                              onClick={
                                callProgress === constant.CALL_MEMBER ? initiateCall : hangupCall
                              }
                              disabled={callProgress === constant.INITIATING_CALL}
                            >
                              {callProgress === constant.CALL_MEMBER
                                ? `Yes, Call Member`
                                : `hang up`}
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  )}
                </Grid>
              ) : (
                callProgress !== constant.CALL_COMPLETED &&
                callProgress !== constant.NOT_ANSWERED && (
                  <Grid item xs={12}>
                    <Paper
                      style={{
                        backgroundColor: 'white',
                        borderRadius: 5,
                        padding: '20px',
                      }}
                    >
                      <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                          <Typography variant={'h4'}>
                            {callProgress === constant.CALL_MEMBER
                              ? callProgressConfirmationText.CONFIRM_CALLING
                              : callProgress === constant.CALL_IN_PROGRESS
                              ? null
                              : callProgressConfirmationText.CALLING}
                          </Typography>
                          <Typography variant={'h4'}>
                            {`${profileData?.participantLastName}, ${profileData?.participantFirstName}`}
                            {callProgress === constant.CALL_MEMBER
                              ? `?`
                              : callProgress === constant.CALL_IN_PROGRESS
                              ? null
                              : `...`}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            columnGap: '10px',
                          }}
                        >
                          {callProgress === constant.CALL_MEMBER && (
                            <Button variant="outlined" color="success" onClick={handleClose}>
                              {buttonText.CANCEL_CALL}
                            </Button>
                          )}
                          {(callProgress === constant.INITIATING_CALL ||
                            callProgress === constant.CONNECTING ||
                            callProgress === constant.CALL_MEMBER) && (
                            <Button
                              variant="contained"
                              color={callProgress === constant.CALL_MEMBER ? 'success' : 'error'}
                              onClick={
                                callProgress === constant.CALL_MEMBER ? initiateCall : hangupCall
                              }
                              disabled={callProgress === constant.INITIATING_CALL}
                            >
                              {callProgress === constant.CALL_MEMBER
                                ? buttonText.CALL_MEMBER
                                : buttonText.HANGUP}
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )
              )}
            </Grid>
          </Resizable>
        </Paper>
      </Draggable>
    </Root>,
    document.getElementById('floating-root'),
  );
}

export default AdhocCallWindow;
