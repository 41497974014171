/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from 'react';
import ListPage from '../Shared/List/ListPage';
import { getAge } from 'utilities/Utilities';
import { Container } from '@material-ui/core';
import ReportModal from './ReportModal';
import apiservice from 'api/services/apiservice.service';
import { streamToPromise, getTimezoneName } from 'utilities/Utilities';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import Modal from './AppointmentDetailsModal';
import Details from './Details';
import moment from 'moment';
import swal from 'sweetalert';
import { TeleKardiaAPI } from '../../Axios/axios';
import { getToken } from 'auth/AuthUtilities';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReAssignAppointment from '../Dashboard/ReAssignAppointment';
import { useTranslation } from 'react-i18next';
import { DOCTOR_ROUTES } from 'constants/app';
import { appointmentStatusName } from 'utilities/Constants';
import ConsultationsDetailModal from 'components/AdminConsultations/ConsultationsDetailModal';
import PreviewReportModal from 'components/AppointmentDetails/PreviewReportModal';

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

function AllAppointments(props) {
  const { t } = useTranslation();
  const { history } = props;
  const mediawidth = useWidth();
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const [, setpageSize] = useState('');
  const [isReportModalOpen, setisReportModalOpen] = useState(false);
  const [isAppointmentDetailModalOpen, setisAppointmentDetailModalOpen] = useState(false);
  const [appointidofmodal, setappointidofmodal] = useState('');
  const [patientinfo, setpatientinfo] = useState({});
  const [appointmentinfofull, setappointmentinfofull] = useState({});
  const [sortPropsName, setSortPropsName] = useState('Date');
  const [sortordertype, setSortordertype] = useState('desc');
  const [startDate, setstartDate] = useState(new Date());
  const [memberId, setmemberId] = useState('');
  const [isfuturescheduledappintment, setisfuturescheduledappintment] = useState(false);
  const timezoneValue = getTimezoneName();
  const [resetdata, setresetdata] = useState(false);
  useEffect(() => {
    return () => {
      setisReportModalOpen(false);
      setisAppointmentDetailModalOpen(false);
      setappointidofmodal(false);
    };
  }, []);
  const reportmodalCloseHandler = () => {
    setisReportModalOpen(false);
  };

  const appointmentDetailModalCloseHandler = () => {
    setisAppointmentDetailModalOpen(false);
  };

  const loadAction = useCallback(async (pageDetail) => {
    setresetdata(false);
    // Assign promises to variables so they run in parallel
    const skip = pageDetail.pageNumber ? (pageDetail.pageNumber - 1).toString() : '0';
    const nonStringSkip = pageDetail.pageNumber ? pageDetail.pageNumber : 1;
    const searchInput = pageDetail.searchInput ? pageDetail.searchInput.toString() : '';

    // const sortpropsname = pageDetail.sortprops && pageDetail.sortprops.sortparameters ? pageDetail.sortprops.sortparameters.headername : undefined;

    // const ordertype = pageDetail.sortprops && pageDetail.sortprops.sortparameters ? pageDetail.sortprops.sortparameters.order : undefined;

    const take = pageDetail.take ? pageDetail.take.toString() : '10';
    setpageSize(take);

    const sortpropsname =
      pageDetail.sortprops && pageDetail.sortprops.sortparameters
        ? pageDetail.sortprops.sortparameters.headername
        : 'Date';

    const ordertype =
      pageDetail.sortprops && pageDetail.sortprops.sortparameters
        ? pageDetail.sortprops.sortparameters.order
        : 'desc';

    if (pageDetail.filter && Object.keys(pageDetail.filter).length > 0) {
      setSortPropsName(sortpropsname);
      setSortordertype(ordertype === 'desc' ? 'desc' : 'asc');
      let sortpropsnamenew =
        sortpropsname === t('Name')
          ? 'patient'
          : sortpropsname === t('Date')
          ? 'date'
          : sortpropsname === t('Time')
          ? 'time'
          : sortpropsname;
      let sortordertypenew =
        ordertype === 'desc' ? 'descending' : ordertype === 'asc' ? 'ascending' : undefined;
      const localfilterstatus = get(pageDetail.filter, 'status') || '';
      let localfilterstatusfinal =
        localfilterstatus === '100' || localfilterstatus.includes('100') ? '' : localfilterstatus;
      const startDateformatted = get(pageDetail.filter, 'startDate') || '';
      const endDateformatted = get(pageDetail.filter, 'endDate') || '';
      let offsetInMinutes = new Date().getTimezoneOffset();
      const appointmentRequest = streamToPromise(
        apiservice.getapiservice({
          baseUrl: `api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&appointmentStatus=${localfilterstatusfinal}&sortOrder=${sortordertypenew}&searchString=${searchInput}&sortParam=${sortpropsnamenew}&timeZone=${timezoneValue}&startDateTime=${startDateformatted}&endDateTime=${endDateformatted}`,
        }),
      );

      const appointments = await appointmentRequest;
      setmemberId(appointments.data.result.memberId);
      const data = appointments.data.result.appointments
        // .filter(
        //   (x) =>
        //     x.patient.firstName
        //       .toLowerCase()
        //       .includes(searchInput.trim().toLowerCase()) ||
        //     x.patient.lastName
        //       .toLowerCase()
        //       .includes(searchInput.trim().toLowerCase()) ||
        //     x.appointmentStatusDisplayName
        //       .toLowerCase()
        //       .includes(searchInput.toLowerCase()) ||
        //     x.patient.gender
        //       .charAt(0)
        //       .toLowerCase()
        //       .includes(searchInput.toLowerCase()) ||
        //       (x.patient.firstName.concat(" ").concat(x.patient.lastName)).toLowerCase()
        //     .includes(searchInput.trim().toLowerCase()) ||
        //     (x.patient.firstName.concat(x.patient.lastName)).toLowerCase()
        //     .includes(searchInput.trim().toLowerCase())
        // )
        .map((item) => {
          return {
            ...item,
            name: `name-${item.patient.lastName === null ? '-' : item.patient.lastName}, ${
              item.patient.firstName === null ? '-' : item.patient.firstName
            }`,
            date: `${moment(item.scheduledStartTime).format('MM-DD-YYYY')} `,
            Status: item.appointmentStatusDisplayName,
            reasonofvisit: item.visitReason && item.visitReason.length > 0 ? item.visitReason : '-',
            programType: item.appointmentType,
            Gender: item.patient.gender.charAt(0),
            Age: getAge(item.patient.dob),
            closedReasonType: `closedReasonType${item.closedReasonType}`,
            appointmentStatusId: `appointmentStatusId${item.appointmentStatusId}`,
            disabledAction:
              item.isOverReadReportCompleted || item.appointmentStatusDisplayName === 'Completed',
            Time: `${moment(item.scheduledStartTime).format('hh:mm A')}-${moment(
              item.scheduledEndTime,
            ).format('hh:mm A')} (${Math.round(
              moment
                .duration(moment(item.scheduledEndTime).diff(moment(item.scheduledStartTime)))
                .asMinutes(),
            )}mins)`,
            appointmentId: `${item.appointmentId}`,
            patientId: `${item.patient.patientId}`,
          };
        });

      return {
        data,
        meta: {
          count: appointments.data.result.totalElements,
          // searchInput !== ""
          //   ? data.length
          //   : appointments.data.result.totalElements,
          currentPage: nonStringSkip,
          // currentPage: searchInput !== "" ? 1 : nonStringSkip,
        },
      };
    } else {
      const pattern = 'YYYY-MM-DDTHH:mm:ss.SSS';
      setSortPropsName(sortpropsname);
      setSortordertype(ordertype === 'desc' ? 'desc' : 'asc');
      let sortpropsnamenew =
        sortpropsname === t('Name')
          ? 'patient'
          : sortpropsname === t('Date')
          ? 'date'
          : sortpropsname === t('Time')
          ? 'time'
          : sortpropsname;
      let sortordertypenew =
        ordertype === 'desc' ? 'descending' : ordertype === 'asc' ? 'ascending' : undefined;
      // let startDateTime = moment(moment().startOf("day")._d).format(pattern);
      // let endDateTime = moment(moment().endOf("day")._d).format(pattern);
      let offsetInMinutes = new Date().getTimezoneOffset();
      let status = '1,2,3,4,5,6,7,8,9,10';
      const appointmentRequest = streamToPromise(
        apiservice.getapiservice({
          baseUrl: `api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments?offsetInMinutes=${offsetInMinutes}&pageNumber=${skip}&pageSize=${take}&appointmentStatus=${status}&searchString=${searchInput}&sortOrder=${sortordertypenew}&sortParam=${sortpropsnamenew}&timeZone=${timezoneValue}`,
        }),
      );

      const appointments = await appointmentRequest;
      setmemberId(appointments.data.result.memberId);
      const data = appointments.data.result.appointments
        // .filter(
        //   (x) =>
        //     x.patient.firstName
        //       .toLowerCase()
        //       .includes(searchInput.trim().toLowerCase()) ||
        //     x.patient.lastName
        //       .toLowerCase()
        //       .includes(searchInput.trim().toLowerCase()) ||
        //     x.appointmentStatusDisplayName
        //       .toLowerCase()
        //       .includes(searchInput.toLowerCase()) ||
        //     (x.patient.firstName.concat(" ").concat(x.patient.lastName)).toLowerCase()
        //     .includes(searchInput.trim().toLowerCase()) ||
        //     (x.patient.firstName.concat(x.patient.lastName)).toLowerCase()
        //     .includes(searchInput.trim().toLowerCase()) ||

        //     x.patient.gender
        //       .charAt(0)
        //       .toLowerCase()
        //       .includes(searchInput.toLowerCase())
        // )
        .map((item, index) => {
          return {
            ...item,
            name: `name-${item.patient.lastName === null ? '-' : item.patient.lastName}, ${
              item.patient.firstName === null ? '-' : item.patient.firstName
            }`,
            date: `${moment(item.scheduledStartTime).format('MM-DD-YYYY')}`,
            Status: item.appointmentStatusDisplayName,
            reasonofvisit: item.visitReason && item.visitReason.length > 0 ? item.visitReason : '-',
            closedReasonType: `closedReasonType${item.closedReasonType}`,
            appointmentStatusId: `appointmentStatusId${item.appointmentStatusId}`,
            programType: item.appointmentType,
            disabledAction:
              item.isOverReadReportCompleted === true ||
              item.appointmentStatusDisplayName === 'Completed',
            Gender: item.patient.gender,
            Age: getAge(item.patient.dob),
            Time: `${moment(item.scheduledStartTime).format('hh:mm A')}-${moment(
              item.scheduledEndTime,
            ).format('hh:mm A')}`,
            appointmentId: `${item.appointmentId}`,
            patientId: `${item.patient.patientId}`,
          };
        });
      return {
        data,
        meta: {
          count: appointments.data.result.totalElements,
          // searchInput !== ""
          //   ? data.length
          //   : appointments.data.result.totalElements,
          // currentPage: searchInput !== "" ? 1 : nonStringSkip,
          currentPage: nonStringSkip,
        },
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const drillDownAction = useCallback(({ entity, history }) => {
  //   history.push({
  //     pathname: `/clinicanalysis`,
  //     clinic: entity
  //   });
  // }, []);

  // const uploadappointmentsAction = useCallback(({ entity, history: hstry }) => {
  //   hstry.push({
  //     pathname: `/uploadAppointments`,
  //     clinic: entity
  //   });
  // }, []);

  const editAction = useCallback(({ entity, history: hstry }) => {
    // if (entity.appointmentStatusDisplayName === 'Completed' || entity.isOverReadReportCompleted) {
    //   setappointidofmodal(entity.appointmentId);
    //   setpatientinfo(entity.patient);
    //   setappointmentinfofull(entity);
    //   setisReportModalOpen(true);
    // } else {
    if (
      entity.appointmentStatusDisplayName === 'Scheduled' &&
      moment(entity.scheduledStartTime) > moment()
    ) {
      setisfuturescheduledappintment(true);
    } else {
      setisfuturescheduledappintment(false);
    }
    setappointidofmodal(entity.appointmentId);
    setstartDate(entity.scheduledStartTime);
    setpatientinfo(entity.patient);
    setappointmentinfofull(entity);
    setisAppointmentDetailModalOpen(true);
    // }
    // hstry.push({
    //   pathname: `/editClinic/${clinic.clinicID}`,
    //   clinic: clinic
    // });
  }, []);
  const openPatientProfile = (pId, appId, status) => {
    if (
      [
        appointmentStatusName.SCHEDULED,
        appointmentStatusName.INPROGRESS,
        appointmentStatusName.WAITING_ROOM,
        appointmentStatusName.ENCOUNTER_SUMMARY,
      ].includes(status)
    ) {
      history.push(`${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${pId}/${appId}`);
    } else {
      history.push(`${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${pId}`);
    }
  };
  const [reassignAppointment, setreassignAppointment] = React.useState(false);
  const reassignAppointmentOpen = () => {
    setreassignAppointment(true);
  };
  const reassignmodalHandler = () => {
    setreassignAppointment(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deleteHandler = useCallback(async () => {
    await TeleKardiaAPI.delete(
      `/api/v1/clinicians/${clinicianId}/appointment/${appointidofmodal}`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      },
    )
      .then((res) => {
        setisAppointmentDetailModalOpen(false);
        setreassignAppointment(false);
        setresetdata(true);
      })
      .catch((err) => {
        setreassignAppointment(false);
      });
  }, []);

  useEffect(() => {
    return () => {
      setisReportModalOpen(false);
      setisAppointmentDetailModalOpen(false);
      setreassignAppointment(false);
    };
  }, []);
  const reloadHandler = () => {
    setisAppointmentDetailModalOpen(false);
    setreassignAppointment(false);
    setresetdata(true);
  };

  const openEditReport = () => {
    appointmentDetailModalCloseHandler();
    history.push(
      `${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${
        patientinfo.patientId
      }/${appointidofmodal}/${true}`,
    );
  };

  const [viewCompletedReportModal, setviewCompletedReportModal] = useState({
    open: false,
    appointmentId: null,
    patientId: null,
  });
  const viewCompletedReportAction = (entity) => {
    setviewCompletedReportModal({
      open: true,
      appointmentId: entity.appointmentId,
      patientId: entity.patient.patientId,
    });
  };
  const handlePreviewReportModalClose = () => {
    setviewCompletedReportModal({
      open: false,
    });
  };

  return (
    <>
      <div className="row no-gutters all_appointments">
        <div className="col-12">
          <Container maxWidth="xl">
            {isReportModalOpen === true && appointidofmodal !== '' && (
              <ReportModal
                key="report-modal"
                appointmentId={appointidofmodal}
                patient={patientinfo}
                patientId={patientinfo.patientId}
                memberId={memberId}
                show={isReportModalOpen}
                handleClose={() => reportmodalCloseHandler()}
                // onSubmit={(e) => this.submitHandler(e)} title={"Update Availability"}
              ></ReportModal>
            )}
            {viewCompletedReportModal.open && (
              <PreviewReportModal
                open={viewCompletedReportModal.open}
                memberId={memberId}
                patientId={viewCompletedReportModal.patientId}
                appointmentId={viewCompletedReportModal.appointmentId}
                handlePreviewReportModalClose={handlePreviewReportModalClose}
              />
            )}

            {isAppointmentDetailModalOpen && (
              <Modal
                onSubmit={() => reassignAppointmentOpen()}
                isfuturescheduledappintment={isfuturescheduledappintment}
                appointmentinfofull={appointmentinfofull?.appointmentStatusId}
                show={isAppointmentDetailModalOpen}
                handleClose={() => appointmentDetailModalCloseHandler()}
                component={'popover'}
                openEditReport={openEditReport}
              >
                <Details appointmentId={appointidofmodal} clinicianId={clinicianId} />
              </Modal>
            )}
            <ListPage
              resetdata={resetdata}
              feature="Appointments"
              entityProps={
                mediawidth === 'xl'
                  ? [
                      'date',
                      'Time',
                      'name',
                      'Status',
                      'programType',
                      'Gender',
                      'Age',
                      'disabledAction',
                      'closedReasonType',
                      'appointmentStatusId',
                    ]
                  : mediawidth === 'lg'
                  ? [
                      'date',
                      'Time',
                      'name',
                      'Status',
                      'programType',
                      'Gender',
                      'Age',
                      'disabledAction',
                      'closedReasonType',
                      'appointmentStatusId',
                    ]
                  : mediawidth === 'md'
                  ? [
                      'date',
                      'Time',
                      'name',
                      'Status',
                      'programType',
                      'Gender',
                      'Age',
                      'disabledAction',
                      'closedReasonType',
                      'appointmentStatusId',
                    ]
                  : [
                      'date',
                      'name',
                      'Status',
                      'Time',
                      'disabledAction',
                      'closedReasonType',
                      'appointmentStatusId',
                    ]
              }
              addAction={() => history.push(`/createClinic`)}
              loadAction={loadAction}
              loading="false"
              history={history}
              sortPropsName={sortPropsName}
              sortordertype={sortordertype}
              headers={
                mediawidth === 'xl'
                  ? [
                      t('Date'),
                      t('Time'),
                      t('Member'),
                      t('Status'),
                      t('Program Type'),
                      t('Gender'),
                      t('Age'),
                    ]
                  : mediawidth === 'lg'
                  ? [
                      t('Date'),
                      t('Time'),
                      t('Member'),
                      t('Status'),
                      t('Program Type'),
                      t('Gender'),
                      t('Age'),
                    ]
                  : mediawidth === 'md'
                  ? [
                      t('Date'),
                      t('Time'),
                      t('Member'),
                      t('Status'),
                      t('Program Type'),
                      t('Gender'),
                      t('Age'),
                    ]
                  : [t('Date'), t('Name'), t('Status'), t('Time')]
              }
              searchEnabled={true}
              title={'Appointments'}
              editAction={editAction}
              openPatientProfileAction={openPatientProfile}
              viewCompletedReportAction={viewCompletedReportAction}
              toggleField={'isActive'}
              filters={[
                {
                  name: 'test',
                  options: [
                    { value: '', label: 'Select a filter' },
                    { value: '123', label: 'Example filter' },
                  ],
                },
              ]}
            />
          </Container>
        </div>
      </div>
      {reassignAppointment && (
        <ReAssignAppointment
          show={reassignAppointment}
          onSubmit={reloadHandler}
          handleClose={reassignmodalHandler}
          onCancel={deleteHandler}
          patientDetails={appointmentinfofull}
          startDate={startDate}
          appointmentId={appointidofmodal}
          component="appointments"
        />
      )}
    </>
  );
}

export default AllAppointments;
