import * as DoctorScreens from 'screens/doctor';
import * as CommmonScreens from 'screens/common';
import * as CoachScreens from 'screens/coach';
import { DOCTOR_ROUTES, ROUTES } from 'constants/app';
import AdminConsultations from 'components/AdminConsultations/AdminConsultations';
import Feedbacks from 'components/Feedbacks/Feedbacks';
import AdminCalendar from 'components/AdminCalendar/AdminCalendar';
import AllAppointments from 'components/AllAppointments/AllAppointments';
import React from 'react';
import Logout from 'components/Logout/Logout';
import EkgReview from 'components/ekg/EkgReview/EkgReview';
import EkgReport from 'screens/doctor/EkgReport/EkgReport';

export const Doctor = [
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ROOT,
    component: DoctorScreens.DoctorTeleconnectScreen,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ECG,
    component: DoctorScreens.EkgList,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_REVIEW,
    component: EkgReview,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_AUDIO,
    component: DoctorScreens.AudioScreen,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_AUDIO_TAB,
    component: DoctorScreens.AudioScreen,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: `${DOCTOR_ROUTES.TELEKARDIA_AUDIO_REVIEW}/:id/:request`,
    component: DoctorScreens.ReviewAudio,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_DASHBOARD,
    component: DoctorScreens.DoctorTeleconnectScreen,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_CONSULTATION,
    component: DoctorScreens.DoctorConsultations,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ALL_APPOINTMENT,
    component: AllAppointments,
  },
  {
    exact: true,
    path: ROUTES.SETTINGS,
    component: CommmonScreens.Settings,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: `${DOCTOR_ROUTES.PATIENT_PROFILE_APPOINTMENT}`,
    component: CoachScreens.NewMUIScreens,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: `${DOCTOR_ROUTES.PATIENT_PROFILE_POST_SESSION}`,
    component: CoachScreens.NewMUIScreens,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.PATIENT_PROFILE,
    component: CoachScreens.NewMUIScreens,
  },
  {
    exact: true,
    path: DOCTOR_ROUTES.DOCTOR_RESOURCES,
    component: CommmonScreens.ComingSoonPage,
  },
  {
    path: `/logout`,
    component: () => <Logout />,
  },
];
export const Admin = [
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ROOT,
    component: DoctorScreens.DoctorAdminDashboard,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ECG,
    component: DoctorScreens.EkgList,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ADMIN_REVIEW,
    component: EkgReview,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ADMIN_REPORT,
    component: EkgReport,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ADMIN_DASHBOARD,
    component: DoctorScreens.DoctorAdminDashboard,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_AUDIO,
    component: DoctorScreens.AudioScreen,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_AUDIO_TAB,
    component: DoctorScreens.AudioScreen,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: `${DOCTOR_ROUTES.TELEKARDIA_AUDIO_REVIEW}/:id/:request`,
    component: DoctorScreens.ReviewAudio,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ADMIN_FEEDBACKS,
    component: Feedbacks,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ADMIN_CALENDAR,
    component: AdminCalendar,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.TELEKARDIA_ADMIN_CONSULTATIONS,
    component: AdminConsultations,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: `${DOCTOR_ROUTES.PATIENT_PROFILE_APPOINTMENT}`,
    component: CoachScreens.NewMUIScreens,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: `${DOCTOR_ROUTES.PATIENT_PROFILE_POST_SESSION}`,
    component: CoachScreens.NewMUIScreens,
  },
  {
    exact: true,
    authenticatedRoute: true,
    path: DOCTOR_ROUTES.PATIENT_PROFILE,
    component: CoachScreens.NewMUIScreens,
  },
  {
    exact: true,
    path: ROUTES.SETTINGS,
    component: CommmonScreens.Settings,
  },

  {
    exact: true,
    path: DOCTOR_ROUTES.DOCTOR_RESOURCES,
    component: CommmonScreens.ComingSoonPage,
  },
  {
    path: `/logout`,
    component: () => <Logout />,
  },
];
export const DoctorAdmin = [...Doctor, ...Admin];
