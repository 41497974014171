import * as React from 'react';
import HomeImage from 'assets/images/Icon.png'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import styles from './BreadCrumb.module.css'
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

function handleClick(event) {
  event.preventDefault();
}

export default function BasicBreadcrumbs(props) {
  const history = useHistory();

const imageObject = {
    "Home" : HomeImage
}

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        {props.data?.map((bread,index) => {
            if(bread.type !== "image"){
                return               <Typography variant='body2' onClick = {() => history.push(bread?.url)} underline="hover" target="_blank" rel="noopener noreferrer" href={'/bhjhbjhvjvjvvvj'} style = {{color:props?.data?.length === index+1 ? "#00000061" : "#00000099"}} className= {styles.textStyle}>
                {bread.name}
              </Typography>
            }else return <div onClick = {() => history.push(bread?.url)} style = {{cursor:"pointer",position:"relative",top:"-3px"}}><img src = {HomeImage} className= {styles.textStyle}></img></div>
         })}

      </Breadcrumbs>
    </div>
  );
}
