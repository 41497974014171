import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@mui/material/styles";
import {
  darkBluishGrey,
  jungleGreen,
  white,
  snowWhite,
} from "../../../style/colors";
import { fontFamily } from "../../../style/commonStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: snowWhite,
    minHeight: "100vh",
    overflow: "auto",
    padding: "1rem",
    paddingTop: "0",
  },
  customTabRoot: {
    color: darkBluishGrey,
    boxShadow:
      "0px 1px 2px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 12%), 0px 1px 8px rgb(0 0 0 / 10%)",
  },
  customTabIndicator: {
    backgroundColor: jungleGreen,
    height: "4px",
  },
  tabHeading: {
    fontFamily,
    fontSize: "14px",
    fontWeight: "700",
    lineHegiht: "17px",
    letterSpacing: "0.75px",
    textTransform: "uppercase",
    marginRight: "0.5rem",
  },
  tabSelected: {
    color: jungleGreen,
  },
  tabNotSelected: {
    color: darkBluishGrey,
  },
  incomingAndTotalBadgeContainer: {
    display: "flex",
    alignItems: "center",
  },
  totalCountBadgeContainer: {
    backgroundColor: jungleGreen,
    padding: "0.25rem",
    borderRadius: "100%",
    textAlign: "center",
    color: white,
  },
  totalCountBadge: {
    fontFamily,
    minWidth: "25px",
    borderRadius: "100%",
    textAlign: "center",
    fontWeight: "bold",
  },
  toastContainer: {
    height: "50px",
    position: "relative",
    marginTop: "1rem",
  },
}));

export const muitheme = createTheme({
  palette: {
    primary: {
      main: jungleGreen,
    },
  },
  typography: {
    fontFamily,
  },
});
