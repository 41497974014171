import { Button, styled } from '@mui/material';
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef,
  // useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';

const classes = {
  root: `MuiDataGrid-root`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: '46rem',
    width: '100%',
    '& .MuiDataGrid-root': {
      margin: '20px 25px',
      border: 'none',
      backgroundColor: '#fff',
      cursor: 'pointer',
    },
    '& .MuiTablePagination-toolbar': {
      alignItems: 'baseline',
    },
    '& .MuiDataGrid-main': {
      margin: '0 20px',
    },
    '& .MuiButton-root': {
      border: '1px solid #1976d2',
      margin: '10px',
      padding: '5px 20px',
      color: '#1976d2',
    },
    '& .css-2ulfj5-MuiTypography-root': {
      fontFamily: 'Work Sans',
      fontSize: '20px',
      fontWeight: 500,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontFamily: 'Work Sans',
      fontSize: '14px',
      fontWeight: 600,
    },

    '& .MuiDataGrid-virtualScroller': {
      width: '875px',
      height: '360px',
    },
    '& .MuiDataGrid-columnSeparator--resizable': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-toolbarContainer': {
      borderBottom: '1px solid lightgrey',
    },
    '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
      backgroundColor: '#F1F2F3',
    },
    '& .MuiMenu-list': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

function MuiTableComponent(props) {
  const {
    loading,
    // styles = {},
    columnToolbar,
    filterToolbar,
    densityToolbar,
    exportToolbar,
    customToolbar = false,
    handleCustomToolbarClick,
    setFilterModel,
    customToolbarText,
    noColumnMargin,
    onRowClick,
    sortingFeature,
    sortModel
  } = props;
  const apiRef = useGridApiRef();

  function CustomToolbar() {
    return (
      <div style={{ marginLeft: noColumnMargin && noColumnMargin }}>
        <GridToolbarContainer>
          {columnToolbar && <GridToolbarColumnsButton />}
          {filterToolbar && <GridToolbarFilterButton />}
          {densityToolbar && <GridToolbarDensitySelector />}
          {exportToolbar && <GridToolbarExport />}
          {customToolbar && (
            <Button color="primary" startIcon={<AddIcon />} onClick={handleCustomToolbarClick}>
              {customToolbarText}
            </Button>
          )}
        </GridToolbarContainer>
      </div>
    );
  }

  return (
    <Root className={classes.root}>
      <DataGridPremium
        {...props}
        apiRef={apiRef}
        loading={loading}
        disableSelectionOnClick
        components={{ Toolbar: CustomToolbar }}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={(data) => setFilterModel(data)}
        onRowClick={onRowClick}
        sortModel={sortModel}
        onSortModelChange={sortingFeature}
      />
    </Root>
  );
}

export default MuiTableComponent;
