import { Grid, Paper, Typography, styled } from '@mui/material';
import React from 'react';
import classes from './Section.module.css';

const Root = styled(Paper)(({ theme }) => ({
  '& .MuiTypography-body2': {
    fontFamily: 'Work Sans',
    fontSize: '14px',
    color: 'rgba(0,0,0,0.67)',
  },
}));
const SectionBody = ({
  headerText = null,
  subheaderText = null,
  headerElement = null,
  childElements,
  layout = {},
}) => {
  return (
    <Root className={classes.bodyContainer} sx={layout}>
      {headerText != null && <Typography variant="body2">{headerText}</Typography>}
      {subheaderText != null && <Typography>{subheaderText}</Typography>}
      {headerElement !== null && <Grid container>{headerElement}</Grid>}
      <Grid container>{childElements}</Grid>
    </Root>
  );
};

export default SectionBody;
